import React, {
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Button,
} from "reactstrap";
import "./post.css";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import { CSVDownload } from "react-csv";

// Quill Editor for Event Description
import ReactQuill from "react-quill";
import { displayImage } from "../../helpers/storage_helper";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

// const authUser = JSON.parse(localStorage.getItem("authUser"));
// const saas_parent_id = authUser?.userInfo?.saas_parent_id;
// const is_super_admin = authUser?.userInfo?.is_super_admin;

function UserPosts() {
  const [page, setPage] = useState(1);
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const [filterparam, setFilterparam] = useState("");

  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;
  const show_all_saas_data = JSON.parse(
    localStorage.getItem("roleWiseAccess")
  )?.show_all_saas_data;
  async function fetchData(
    query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`
  ) {
    return await axios.get(
      process.env.REACT_APP_API_URL + `/api/post/user/getall${query}`,
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    );
  }
  async function getPostsAllExport(
    query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`
  ) {
    return await axios.get(
      process.env.REACT_APP_API_URL + `/api/post/user/getall/export${query}`,
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    );
  }
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [posts, setPosts] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [post, setPost] = useState({});
  const [csvExportData, setCSVExportData] = useState([]);
  const [access, setAccess] = useState({});

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
      let query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}${filterparam}`;
      await fetchData(query)
        .then(({ data }) => {
          setPosts(data.posts);
          setTotal(data.dataSize);
        })
        .catch(error => {
          setError("Something went wrong");
        });
    },
  };
  const loadData = useCallback(async () => {
    setLoading(true);
    let query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`;
    await fetchData(query).then(
      ({ data }) => {
        setPosts(data.posts);
        setTotal(data.dataSize);
        setError("");
      },
      error => {
        setError(error.message);
      }
    );
    setLoading(false);
  }, []);
  useLayoutEffect(() => {
    loadData();
  }, []);
  const handleRangeFilterOnUser = (start, end) => {
    const filteredPosts = posts.filter(user => {
      const createdAt = moment(new Date(user.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return user;
      } else {
        return;
      }
    });
    setPosts(filteredPosts);
    setTotal(filteredPosts.length);
  };
  const updatePost = async post => {
    setLoading(true);
    let bodyFormData = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile;
    try {
      compressedFile = await imageCompression(selectedFile, options);
    } catch (error) {
      //  console.log(error);
    }
    let data = {
      title: post.title,
      description: post.description,
      active: post.active,
      post_category: "Posts By User",
    };

    bodyFormData.append("image", compressedFile);
    let encryptedQuery = encryptDataToArray(data);
    bodyFormData.append("q", JSON.stringify(encryptedQuery));
    setModal(false);
    await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/post/user/update/${post._id}`,
      data: bodyFormData,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async res => {
        let query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`;
        await fetchData(query)
          .then(({ data }) => {
            setPosts(data.posts);
            setTotal(data.dataSize);
          })
          .catch(err => {
            setError("Something went wrong");
          });
      })
      .catch(err => {
        setError(err.message);
      });
    setLoading(false);
  };
  const togglePost = post => {
    setLoading(true);
    let bodyFormData = new FormData();
    let data = {
      title: post.title,
      description: post.description,
      active: !post.active,
      post_category: "Posts By User",
    };

    let encryptedQuery = encryptDataToArray(data);
    bodyFormData.append("q", JSON.stringify(encryptedQuery));
    setModal(false);
    axios({
      method: "PATCH",
      // url: `${process.env.REACT_APP_API_URL}/api/post/user/update/${post._id}`,
      url: `${process.env.REACT_APP_API_URL}/api/post/user/status/update/${post._id}`,
      data: bodyFormData,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
        "Content-Type": "multipart/form-data",
      },
    })
      // .then(async res => {
      //   await fetchData()
      //     .then(({ data }) => {
      //       setPosts(data.posts);
      //       setTotal(data.dataSize);
      //       setError("");
      //     })
      //     .catch(err => {
      //       setError(err.message);
      //     });
      // })
      .catch(err => {
        setError(err.message);
      });
    setLoading(false);
  };

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
    return () => {};
  }, []);

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "creadted_by_user_name",
      text: "User Name",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row) => {
        if (row?.user_info) {
          return row?.user_info?.firstname + " " + row?.user_info?.lastname;
        }
        return row?.creadted_by_user_name;
      },
    },
    {
      dataField: "saas",
      text: "Domain Expert name",
      filter: textFilter(),
      formatter: (cell, row, rowIndex, extraData) => {
        //Here I created array and not was able to search on this column.
        return row?.saas[0]?.saas_name;
      },
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter(),
      formatter: (context, post) => (
        <div
          className="react-quill-preview"
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html:
              post?.description?.length > 50
                ? post?.description?.slice(0, 50) + "..."
                : post?.description,
          }}
        ></div>
      ),
    },
    {
      dataField: "post_category",
      text: "Looking for mentorship",
      formatter: (context, post) => {
        if (post.post_category === "Looking For Mentorship") {
          return <div>Yes</div>;
        } else {
          return <div>No</div>;
        }
      },
    },
    access?.userPostStatus
      ? {
          dataField: "active",
          text: "Status",
          formatter: (cellContent, post) => (
            <p>
              <label
                className="toggleSwitch large"
                // checked={post.active ? true : false}
                // onClick={() => togglePost(post)}
              >
                <input
                  type="checkbox"
                  defaultChecked={post.active}
                  onClick={() => togglePost(post)}
                />
                <span>
                  <span></span>
                  <span></span>
                </span>
                <a></a>
              </label>
            </p>
          ),
        }
      : "",
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.updatedAt)).format("DD MMM Y")}</>
      ),
    },
    access?.userPostEditAction
      ? {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, post) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    setPost(post);
                    setModal(true);
                    setIsEdit(true);
                  }}
                ></i>
              </Link>
            </div>
          ),
        }
      : "",
  ];
  const onTableChange = async (action, { filters, sortField, sortOrder }) => {
    if (action === "filter") {
      const creadted_by_user_name =
        filters?.creadted_by_user_name?.filterVal || "";
      const description = filters?.description?.filterVal || "";
      const saas_name = filters?.saas?.filterVal || "";
      let query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}`;

      let filterparam = "";
      if (creadted_by_user_name.length > 0) {
        query += "&creadted_by_user_name=" + creadted_by_user_name;
        filterparam += "&creadted_by_user_name=" + creadted_by_user_name;
      }
      if (description.length > 0) {
        query += "&description=" + description;
        filterparam += "&description=" + description;
      }
      if (saas_name.length > 0) {
        query += "&saas_name=" + saas_name;
        filterparam += "&saas_name=" + saas_name;
      }
      setFilterparam(filterparam);
      await fetchData(query).then(({ data }) => {
        setPosts(data.posts);
        setTotal(data.dataSize);
      });
    } else if (action === "sort") {
      setLoading(true);
      const creadted_by_user_name =
        filters?.creadted_by_user_name?.filterVal || "";
      const description = filters?.description?.filterVal || "";
      const saas_name = filters?.saas?.filterVal || "";
      let query = `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}`;

      let filterparam = "";
      if (creadted_by_user_name.length > 0) {
        query += "&creadted_by_user_name=" + creadted_by_user_name;
        filterparam += "&creadted_by_user_name=" + creadted_by_user_name;
      }
      if (description.length > 0) {
        query += "&description=" + description;
        filterparam += "&description=" + description;
      }
      if (saas_name.length > 0) {
        query += "&saas_name=" + saas_name;
        filterparam += "&saas_name=" + saas_name;
      }
      if (sortField) {
        let myOrder;
        if (sortOrder === "asc") {
          myOrder = 1;
        } else {
          myOrder = -1;
        }
        query += "&sortField=" + sortField + "&sortOrder=" + myOrder;
      }
      setFilterparam(filterparam);
      await fetchData(query).then(({ data }) => {
        setPosts(data.posts);
        setTotal(data.dataSize);
        setLoading(false);
      });
    }
  };
  const { ExportCSVButton } = CSVExport;
  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users Posts | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="General Posts" breadcrumbItem="Posts List" /> */}
          <Row className="">
            <Col sm="6">
              <h5 className="text-uppercase">User Posts List</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={posts}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={posts}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4" md="10">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      {/* Date Picker Starts */}
                                      <DateRangePicker
                                        initialSettings={{
                                          startDate: "12/20/2021",
                                          endDate: "12/30/2021",
                                        }}
                                        onApply={(event, picker) =>
                                          handleRangeFilterOnUser(
                                            picker.startDate._d,
                                            picker.endDate._d
                                          )
                                        }
                                        onCancel={async () =>
                                          await fetchData()
                                            .then(({ data }) => {
                                              setPosts(data.posts);
                                              setTotal(data.dataSize);
                                            })
                                            .catch(err => {
                                              setError(err.message);
                                            })
                                        }
                                      >
                                        <button className="btn btn-primary">
                                          Select Range
                                        </button>
                                      </DateRangePicker>
                                      {/* Date Picker Ends */}
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="2" md="2">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      {csvExportData.length > 0 ? (
                                        <CSVDownload
                                          data={csvExportData}
                                          target="_blank"
                                        />
                                      ) : null}
                                      {access?.userPostExport ? (
                                        <Button
                                          onClick={event => {
                                            setLoading(true);
                                            setCSVExportData([]);
                                            getPostsAllExport()
                                              .then(({ data }) => {
                                                // handleRangeFilterOnUser();
                                                setCSVExportData(data.posts);
                                                setLoading(false);
                                              })
                                              .catch(err => {
                                                //  console.log(error);
                                                setLoading(false);
                                              });
                                          }}
                                          color="primary"
                                        >
                                          Export CSV !!!
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    keyField="_id"
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                    }}
                                    data={posts}
                                    columns={columns}
                                    bordered={false}
                                    striped={true}
                                    onTableChange={onTableChange}
                                    responsive
                                    filter={filterFactory()}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) && (
                          <Alert
                            color="danger"
                            className="mt-4"
                            isOpen={error.length > 0}
                          >
                            {error}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Modal
                            size="lg"
                            isOpen={modal}
                            className="mb-3"
                            toggle={() => setModal(false)}
                          >
                            <ModalHeader
                              toggle={() => setModal(false)}
                              tag="h4"
                            >
                              {!!isEdit ? "Edit Post" : "Add Post"}
                            </ModalHeader>
                            <ModalBody>
                              <AvForm
                                onValidSubmit={() => {
                                  updatePost(post);
                                }}
                                model={post}
                              >
                                <Row form>
                                  <Col className="col-12">
                                    <div className="mb-5 pb-3">
                                      <ReactQuill
                                        id="description"
                                        style={{ height: "258px" }}
                                        placeholder="Enter Event Description"
                                        defaultValue={post.description || ""}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            description: event,
                                          });
                                        }}
                                        modules={eventDescriptionEditorModules}
                                        formats={eventDescriptionEditorFormats}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <AvField
                                        name="Image"
                                        label="Post Image"
                                        type="file"
                                        className="inner form-control"
                                        onChange={onSelectFile}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {isEdit &&
                                  post?.media_content[0]?.media_type[0] ===
                                    "image" && (
                                    <Row>
                                      <Col sm={12} className="pb-3">
                                        <a
                                          className=""
                                          href={post?.media_content[0]?.media}
                                          target="_blank"
                                        >
                                          <img
                                            src={displayImage(
                                              post?.media_content[0]?.media
                                            )}
                                            className="img img-fluid img-thumbnail"
                                            style={{
                                              objectFit: "scale-down",
                                              height: "300px",
                                              width: "100%",
                                            }}
                                          />
                                        </a>
                                      </Col>
                                    </Row>
                                  )}
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </ModalBody>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
UserPosts.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
  csvProps: PropTypes.array,
};

export default UserPosts;
