import axios from "axios";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import { RELATION_OPTIONS } from "helpers/storage_helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import {
  getAllUsersForReference,
  getDealManagers,
} from "../../helpers/backend_helper";
import { Link } from "react-router-dom";

const EventListOptimized = props => {
  const params = new URLSearchParams(window.location.search);

  let defaultSearchQuery = {
    sortOrder: -1,
  };
  for (let [key, value] of params) {
    defaultSearchQuery[key] = value;
  }

  // Event List States
  const [eventList, setEventList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [pageno, setPageno] = useState(1);
  const [totalList, setTotalList] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [roleWiseAccess, setRolewiseAccess] = useState(
    localStorage.getItem("roleWiseAccess")
      ? JSON.parse(localStorage.getItem("roleWiseAccess"))
      : {}
  );
  const [selectDataSaas, setSelectDataSaas] = useState([]);

  const fetchEventList = async (searchQuery, pageno) => {
    try {
      setLoading(true);
      setEventList([]);
      const token = authHeader();
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const saas_parent_id = authUser?.userInfo?.saas_parent_id;
      const is_super_admin = authUser?.userInfo?.is_super_admin;
      setAdmin(true);
      const show_all_saas_data = roleWiseAccess?.show_all_saas_data;
      let queryText = `?page=${pageno}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}&`;
      for (let key of Object.keys(searchQuery)) {
        queryText += `${key}=${searchQuery[key] || ""}&`;
      }
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/post/pitchday/getallV2${queryText}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setEventList(response?.data?.posts || []);
      setTotalList(response?.data?.dataSize || 0);
      setLoading(false);
    } catch (error) {
      setEventList([]);
      setTotalList(0);
      setLoading(false);
    }
  };

  const getAllUsersForReferenceNew = async () => {
    await getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_fullname,
              saas_name,
              saas_alias,
              saas_parent_id,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              let temp2 = saas_fullname;
              if (temp2) {
                temp2 += ` (${saas_name})`;
              } else {
                temp2 = saas_alias;
              }
              temp = temp2 || temp;
              saas_parent_id = _id;

              saasPartners.push({
                value: saas_parent_id,
                label: temp,
              });
            }
            setSelectDataSaas(saasPartners);
            return saasPartners;
          })
          .filter(data => !!data);
      })
      .catch(error => {
        setSelectDataSaas([]);
        // console.trace(error);
      });
  };

  useEffect(() => {
    getAllUsersForReferenceNew();
  }, []);

  useEffect(() => {
    fetchEventList(searchQuery, pageno);
  }, [searchQuery, pageno]);

  const defaultSorted = [
    {
      dataField: "updatedAt",
      order: "desc",
    },
  ];

  const eventListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (cellContent, pitchday) => <>{pitchday.id}</>,
    },
    {
      text: "Title",
      dataField: "title",
      sort: true,
      formatter: (cellContent, pitchday) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {pitchday.title}
            </Link>
          </h5>
        </>
      ),
      filter: textFilter(),
    },
    {
      dataField: "event_type",
      text: "Event Type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "selected_saas",
      text: "Domain Expert Name",
      filter: selectFilter({ options: selectDataSaas }),
      formatter: (cellContent, pitchday) => (
        <>{pitchday?.saas?.length ? pitchday.saas[0].saas_name : ""}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated On",
      sort: true,
      formatter: (cellContent, pitchday) => (
        <>
          {moment(new Date(pitchday.updatedAt)).format("DD MMM YYYY hh:mm A")}
        </>
      ),
    },
    {
      dataField: "date.event_date",
      text: "Event On",
      sort: true,
      formatter: (cellContent, pitchday) => (
        <>
          {/* {moment
                .utc(pitchday.date.event_date)
                // .local()
                .format("DD MMM YYYY hh:mm A")} */}

          {localStorage.getItem("zonelist") &&
          JSON.parse(localStorage.getItem("zonelist")) &&
          JSON.parse(localStorage.getItem("zonelist"))?.length &&
          pitchday?.selectedTimezon
            ? moment(pitchday?.date?.event_date)
                .add(
                  JSON.parse(localStorage.getItem("zonelist"))?.filter(
                    item => item?.alias === (pitchday?.selectedTimezon ?? "IST")
                  )?.[0]?.offset
                )
                .format("DD-MMM-YYYY hh:mm A") +
              " " +
              pitchday?.selectedTimezon
            : moment
                .utc(pitchday.date.event_date)
                // .local()
                .add("+05:30")
                .format("DD MMM YYYY hh:mm A") + " IST"}
        </>
      ),
    },
    // {
    //   dataField: "totalUserCount",
    //   text: "Invited Investors/ Founders /Panellist",
    //   sort: true,
    //   formatter: (cellContent, pitchday) => (
    //     <>
    //       {pitchday.invited_investor.length}/{pitchday.invited_founder.length}/
    //       {pitchday?.invited_panellist?.length ?? 0}
    //     </>
    //   ),
    // },
    {
      dataField: "totalUserCount",
      text: "Invited Investors/ Founders /Panellist",
      sort: true,
      formatter: (cellContent, pitchday) => (
        <>
          {pitchday.invitedInvestors}/{pitchday.invitedFounder}/
          {pitchday?.invitedPanelList ?? 0}
        </>
      ),
    },
    {
      // dataField: "invest_count",
      dataField: "total_attendees",
      text: "Total Attendees/ Duration",
      sort: true,
      formatter: (cellContent, pitchday) => (
        <>
          {pitchday?.total_attendees ?? "-"}/{pitchday?.total_duration ?? "-"}
        </>
      ),
    },
    // {
    //   dataField: "invited_founder",
    //   text: "Invited Founders",
    //   sort: true,
    //   formatter: (cellContent, pitchday) => (
    //     <>{pitchday.invited_founder.length}</>
    //   ),
    // },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, pitchday) => {
        // let date = moment.utc().local().format("YYYY-MM-DD HH:mm") + ':00';
        // let abc = date.split('-');
        // let s = abc[2].replace(/^0+/, '');
        // let newDate = abc[0] + "-" + abc[1] + "-" + s;
        // console.log("---newDate---", newDate);
        return (
          <div className="d-flex gap-3">
            {pitchday?.event_type === "General Event" ? (
              roleWiseAccess?.eventAttendanceReport ? (
                moment.utc().local().format("YYYY-MM-DD HH:mm") >=
                moment
                  .utc(pitchday?.date?.event_date)
                  .local()
                  .add(2, "hours")
                  .format("YYYY-MM-DD HH:mm") ? (
                  <Link
                    className={`btn btn-${
                      pitchday?.isGenerated ? "success" : "primary"
                    }`}
                    to={`/create-event-report/${pitchday?._id}`}
                  >
                    {pitchday?.isGenerated ? "View Report" : "Generate Report"}
                  </Link>
                ) : (
                  // <span className="btn btn-success">Attendance Report</span>
                  <Link
                    className="btn btn-primary"
                    to={`/create-event-report/${pitchday?._id}`}
                  >
                    Invitees Report
                  </Link>
                )
              ) : (
                ""
              )
            ) : pitchday?.is_associated ? (
              roleWiseAccess?.eventAttendanceReport ? (
                <Link
                  className="btn btn-success"
                  to={`/create-event-report/${pitchday?._id}`}
                >
                  Attendance Report
                </Link>
              ) : (
                ""
              )
            ) : roleWiseAccess?.eventGenerateReport ? (
              moment.utc().local().format("YYYY-MM-DD HH:mm") >=
              moment
                .utc(pitchday?.date.event_date)
                .local()
                .add(2, "hours")
                .format("YYYY-MM-DD HH:mm") ? (
                <Link
                  className="btn btn-primary"
                  to={`/create-event-report/${pitchday?._id}`}
                >
                  {pitchday?.isGenerated ? "View Report" : "Generate Report"}
                </Link>
              ) : (
                // <span className="btn btn-primary">Generate Report</span>
                <Link
                  className="btn btn-primary"
                  to={`/create-event-report/${pitchday?._id}`}
                >
                  Invitees Report
                </Link>
              )
            ) : (
              ""
            )}
            {isAdmin && (
              <Link className="text-success" to="#">
                <i
                  className="btn btn-primary"
                  id="editooltip"
                  title="Send Invite CTA"
                  onClick={() => sendCTAInvite(pitchday)}
                  style={pitchday?.is_disable ? { color: "grey" } : null}
                >
                  Invite CTA
                </i>
              </Link>
            )}
          </div>
        );
      },
    },
  ];

  const onTableChange = (action, { filters, sortOrder, sortField }) => {
    let searchQuery = Object.entries(filters).reduce(
      (prev, [key, value]) => ({ ...prev, [key]: value?.filterVal }),
      {}
    );

    searchQuery.sortOrder = sortOrder === "asc" ? 1 : -1;
    searchQuery.sortField = sortField;
    setSearchQuery(searchQuery);
  };

  const customTotal = (from, to, size) => {
    if (loading) {
      return <></>;
    }

    if (!size) {
      return <></>;
    }

    let className = "react-bootstrap-table-pagination-total";
    let text = `Showing rows ${from || 0} to ${to || 0} of ${size || 0}`;

    return (
      <>
        <span className={className}>{text}</span>
      </>
    );
  };

  const NoDataIndication = () => {
    if (loading) {
      return (
        <>
          <h5>
            <Spinner animation="border" size="sm" /> Loading . . .
          </h5>
        </>
      );
    }

    if (!eventList?.length) {
      return (
        <>
          <h5>No Events Found.</h5>
        </>
      );
    }

    return <></>;
  };

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "Next page",
    prePageTitle: "Previos page",
    firstPageTitle: "First page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: totalList,
    disablePageTitle: false,
    paginationTotalRenderer: customTotal,
    onPageChange: async (page, size) => {
      setPageno(page);
    },
  };

  const sendCTAInvite = async arg => {
    const req = { id: arg._id };
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/post/pitchDay/sendemail-cta/${req.id}/all`,
        configHeaders
      )
      .then(response => {
        if (response.status === 200) {
          alert("CTA mail send successfully.");
          window.location.href = "/event";
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Event | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Row className="d-flex align-items-center">
            <Col sm="5">
              <h5 className="text-uppercase">Events List</h5>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-0">
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={eventListColumns}
                    data={eventList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={eventListColumns}
                        data={eventList}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                    noDataIndication={<NoDataIndication />}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EventListOptimized;
