import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"

import { Provider } from "react-redux"

import store from "./store"
import "./assets/style.css"

// Suppress the "Add to Home Screen" prompt
window.addEventListener('beforeinstallprompt', (event) => {
  // Prevent the default A2HS prompt
  event.preventDefault();
  //console.log('A2HS prompt has been suppressed.');
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()