import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  Row,
  Alert,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getStartupsListAdmin, closeAlertBox } from "../../store/kpi/actions";
import KPIListingModal from "./kpi-listing-modal";
import { isEmpty } from "lodash";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import axios from "axios";
import DataFetchLoader from "components/Common/DataFetchLoader";
class KPIs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startups: [],
      error: "",
      startup: null,
      show: false,
      success: "",
      roleWiseAccess: {},
      loading: false,
    };
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );
    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
      countryCurrencyCodes: [],
    };
  }

  componentDidMount() {
    const { startups, onGetStartups } = this.props;
    onGetStartups();
    this.setState({ startups });

    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        this.setState({ roleWiseAccess: res.data.roleWiseAccess });
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/generalmaster/getallcurrencies`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(response => {
          if (response?.data?.status) {
            console.log(response.data);
            let { data } = response.data;
            let currency_options = [];
            if (data.length > 0) {
              data.filter((item, index) => {
                currency_options.push(item.value);
              });
            }
            this.setState({ countryCurrencyCodes: currency_options });
          }
        });
    } catch (error) {
      // console.log(error);
    }
  }

  handleShow = () => {
    this.setState({ show: !this.state.show });
  };

  capitalizeStr = str => {
    let cap_str = str;
    cap_str = str
      .split(" ")
      .map((p, i) => `${p.charAt(0).toUpperCase()}${p.slice(1)}`)
      .join(" ");
    return cap_str;
  };

  handleSelect = startup_id => {
    const { startups } = this.props;
    let startup = startups.find(s => s._id == startup_id);

    let formattedKPIDetails = {};
    if (startup) {
      let uid = startup?.user_id || "";
      let user_defined_kpis = (startup?.business_kpis || []).filter(
        k => k.kpi_set_by_user_id === uid
      );
      let admin_defined_kpis = (startup?.business_kpis || []).filter(
        k => k.kpi_set_by_user_id !== uid
      );

      let business_kpi = {};
      admin_defined_kpis.forEach((k, i) => {
        business_kpi[k.name] = k;
      });
      let extra_kpi = {};
      user_defined_kpis.forEach((k, i) => {
        extra_kpi[k.name] = k;
      });

      formattedKPIDetails = {
        startup_id: startup._id,
        currency: startup?.currency,
        startup_name: startup?.basic_information?.startup_name || "",
        kpis: {
          financial_kpi: startup?.kpis?.financial_kpi || {},
          engagement_kpi: startup?.kpis?.engagement_kpi || {},
          business_kpi: business_kpi,
          additional_kpi: extra_kpi,
        },
      };
    }
    this.setState({ startup: formattedKPIDetails });
    this.handleShow();
  };

  handleKPIUpdateDone = () => {
    const { onGetStartups } = this.props;
    onGetStartups();
    console.log("KPI updated done");
  };

  handleAlertClose = () => {
    const { onAlertClose } = this.props;
    onAlertClose();
  };

  formattedKPIsStartup = startupList => {
    let formatted = [];

    for (let startup of startupList) {
      let uid = startup?.user_id || "";

      let financial_kpi = Object.keys(startup?.kpis?.financial_kpi || {}).map(
        (k, i) =>
          this.capitalizeStr(
            startup?.kpis?.financial_kpi[k]?.name || k.replace("_", " ")
          )
      );
      let engagement_kpi = Object.keys(startup?.kpis?.engagement_kpi || {}).map(
        (k, i) =>
          this.capitalizeStr(
            startup?.kpis?.financial_kpi[k]?.name || k.replace("_", " ")
          )
      );

      let user_defined_kpis = (startup?.business_kpis || []).filter(
        k => k.kpi_set_by_user_id === uid
      );
      let admin_defined_kpis = (startup?.business_kpis || []).filter(
        k => k.kpi_set_by_user_id !== uid
      );

      let business_kpi = admin_defined_kpis.map((k, i) =>
        this.capitalizeStr(k?.name?.replace("_", " "))
      );
      let extra_kpi = user_defined_kpis.map((k, i) =>
        this.capitalizeStr(k?.name?.replace("_", " "))
      );

      let formatted_startup = {
        startup_id: startup._id,
        startup_name: startup?.basic_information?.startup_name || "",
        financial_kpi: financial_kpi,
        engagement_kpi: engagement_kpi,
        business_kpi: business_kpi,
        additional_kpi: extra_kpi,
        currency: startup?.currency,
      };
      formatted.push(formatted_startup);
    }
    return formatted;
  };

  render() {
    const columns = [
      {
        text: "id",
        dataField: "_id",
        sort: true,
        hidden: true,
        formatter: (cellContent, startup) => <>{startup.id}</>,
      },
      {
        dataField: "startup_name",
        text: "Startup Name",
        filter: textFilter(),
      },
      {
        dataField: "financial_kpi",
        text: "Financial KPIs",
        formatter: (cellContent, startup) => (
          <>{startup.financial_kpi?.join(", ")}</>
        ),
        filter: textFilter(),
      },
      {
        dataField: "engagement_kpi",
        text: "Engagement KPIs",
        formatter: (cellContent, startup) => (
          <>{startup.engagement_kpi?.join(", ")}</>
        ),
        filter: textFilter(),
      },
      {
        dataField: "business_kpi",
        text: (
          <span className="display-linebreak tex-center">
            {" Business KPIs\n( Set by Admin )"}
          </span>
        ),
        formatter: (cellContent, startup) => (
          <>{startup.business_kpi?.join(", ")}</>
        ),
        filter: textFilter(),
      },
      {
        dataField: "additional_kpi",
        text: (
          <span className="display-linebreak">
            {"  Business KPIs\n( Set by Founder )"}
          </span>
        ),
        formatter: (cellContent, startup) => (
          <>{startup.additional_kpi?.join(", ")}</>
        ),
        filter: textFilter(),
      },
      this.state.roleWiseAccess?.startupKpiEditAction
        ? {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: (
              <span className="display-linebreak">{"View / Edit / Set "}</span>
            ),
            formatter: (cellContent, startup) => (
              <div className="d-flex gap-3">
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    title="Click to View / Edit / Set KPI for startup"
                    id="edittooltip"
                    onClick={() => this.handleSelect(startup.startup_id)}
                  ></i>
                </Link>
              </div>
            ),
          }
        : "",
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>KPIs | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <DataFetchLoader loading={this.state.loading} />
            {/* <Breadcrumbs  breadcrumbItem="KPIs" /> */}
            {/* Render Breadcrumbs */}
            <Row className="">
              <Col sm="6">
                <h5 className="text-uppercase mb-0">STARTUP KPIs</h5>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                {!isEmpty(this.state.error) ? (
                  <Alert color="danger" toggle={this.handleAlertClose}>
                    Some error occured!
                  </Alert>
                ) : null}
                {!!this.state.success ? (
                  <Alert color="success" toggle={this.handleAlertClose}>
                    {this.state.success}
                  </Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <DataFetchLoader
                  loading={
                    this.state.roleWiseAccess?.startupKpiEditAction
                      ? false
                      : true
                  }
                />
                <React.Fragment>
                  <PaginationProvider
                    pagination={paginationFactory(this.options)}
                    keyField="_id"
                    columns={columns}
                    data={this.props.startups}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={this.props.startups}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            {...paginationTableProps}
                            keyField="_id"
                            data={this.formattedKPIsStartup(
                              this.props.startups
                            )}
                            columns={columns}
                            bordered={false}
                            striped={true}
                            responsive
                            filter={filterFactory()}
                          />
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </React.Fragment>
              </Col>
            </Row>
          </Container>
          {this.state.show && (
            <KPIListingModal
              CurrencyCodes={this.state.countryCurrencyCodes}
              startup_kpis={this.state.startup}
              onClose={this.handleShow}
              onKPIUpdateDone={this.handleKPIUpdateDone}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

KPIs.propTypes = {
  startups: PropTypes.array,
  onGetStartups: PropTypes.func,
  onAlertClose: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ kpi }) => ({
  startups: kpi.startups,
  error: kpi.error,
  success: kpi.success,
});

const mapDispatchToProps = dispatch => ({
  onGetStartups: () => dispatch(getStartupsListAdmin()),
  onAlertClose: () => dispatch(closeAlertBox()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KPIs));
