import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import DragDropRounded from "../../../components/DragDropRounded";
import logoLightSvg from "../../../assets/images/removeIcon.svg";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import { Accordion, Form as Form1 } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";

import {
  getUsers2 as getUsersAll,
  getUserAdditionalInfo,
  addNewUser,
  updateUser,
  deleteUser,
  getAllUsersForReference,
  getAllUsersForReferenceEvenDisabled,
  saveUserExportMail,
  updatePartnerUserInfoUserList,
  getAllPartnersForEmployees,
  getDealManagers,
  getAllInvestmentVehicles,
  getAllVPPayoutEnabledSyndicates,
  verifyAdminEmailOTP,
} from "../../../helpers/backend_helper";
import axios from "axios";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { loginAsUser } from "../../../helpers/backend_helper";

// Loader for data fetch
import DataFetchLoader from "../../../components/Common/DataFetchLoader";

import {
  CountryData,
  StateData,
  CityData,
} from "../../../components/Common/CityStateCountryComponent";

// For User CSV Export
import { CSVDownload } from "react-csv";
import ReactQuill from "react-quill";
import SyndicateOptions from "./SyndicateOptions";
import {
  getReferralInvestmentSettingsKey,
  getSelfInvestmentSettingsKey,
  getSyndicatePayoutSettingsKey,
} from "helpers/common";
import { isEmpty } from "lodash";
import { getAllAccessSettings } from "store/actions";
import { connect } from "react-redux";
import { all } from "redux-saga/effects";
import { getAdminUserInfo } from "helpers/storage_helper";
import { encryptDataToArray } from "helpers/EncryptDecrypt";
let userllistIMS = [];

const UsersList = ({
  getAllAccessSettingsFunction,
  allAccessSettings = [],
  ...props
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [csvFile, setCsvFile] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [coreMembers, setCoreMembers] = useState([]);
  const [profileImages, setProfileImages] = useState([]);
  const [modal, setModal] = useState(false);
  const [is_admin, setIsAdmin] = useState("false");
  const [user, setUser] = useState({});
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [longDescriptionLength, setLongDescriptionLength] = useState(0);
  const [portfolioLength, setPortfolioLength] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [demoCsvExportData, setDemoCSVExportData] = useState([]);
  const [demoInvestmentExportData, setInvestmentCSVExportData] = useState([]);
  const [investmentManagers, setinvestmentManagers] = useState([]);
  const [investmentManagersFilter, setinvestmentManagersFilter] = useState([]);
  const [investment_manager_id, setInvestment_manager_id] = useState(null);
  const [investment_MID, setInvestment_MID] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [userInfo, setUserInfo] = useState({});
  const [partnerUserInfo, setPartnerUserInfo] = useState({});
  const [investmentToggle, setInvestmentToggle] = useState(false);
  const [userListIM, setUserListIM] = useState([]);

  const [displaySaasLogo, setDisplaySaasLogo] = useState("");
  const [displaySaasFaviconLogo, setDisplaySaasFaviconLogo] = useState("");
  const [displayPortfolioImage, setDisplayPortfolioImage] = useState("");
  const [displayCoreMembers, setDisplayCoreMembers] = useState([]);

  const [saasAliasError, setSaasAliasError] = useState(null);

  const [roleWiseAccess, setRoleWiseAccess] = useState([]);
  const [roleId, setRoleId] = useState();

  const [country, setCountry] = useState();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();

  const [referenceUserList, setReferenceUserList] = useState([]);
  const [selectedRefUser, setSelectedRefUser] = useState(null);
  const [exportAdminMail, setExportAdminMail] = useState("");
  const [exportType, setExportType] = useState("all");
  const [exportMessage, setExportMessage] = useState({
    success: "",
    error: "",
  });

  const [loginUser, setLoginUserData] = useState({});

  const [saasTypesList, setSaasTypesList] = useState(
    [
      "Domain Expert",
      "Incubator - College",
      "Incubator - City-State",
      "Incubator - Institute",
      "Incubator - Corporate",
      "Individual Startup",
      "VP - Company",
    ].map(item => ({ value: item, label: item }))
  );
  const consentToIntentList = [
    { label: "Minimum", value: "min" },
    { label: "Average", value: "avg" },
    { label: "Maximum", value: "max" },
  ];
  const syndicateTypeList = ["Public", "Private", "Affiliate"].map(item => ({
    value: item?.toLowerCase(),
    label: item,
  }));
  const [saasPartnersList, setSaasPartnersList] = useState([]);
  const [selectedSaasPartner, setSelectedSaasPartner] = useState({
    value: JSON.parse(localStorage.getItem("authUser"))?.userInfo
      ?.saas_parent_id,
  });

  const [partnerType, setPartnerType] = useState("partner");
  const [allPartners, setAllPartners] = useState([]);
  const [selectedRefPartner, setSelectedRefPartner] = useState(null);
  const [access, setAccess] = useState({});

  const [selectedSaas, setSelectedSaas] = useState({
    value: JSON.parse(localStorage.getItem("authUser"))?.userInfo
      ?.saas_parent_id,
    label: "Select Domain Expert Parent",
  });

  const [selectedSaasExport, setSelectedSaasExport] = useState({
    value: "all",
    label: "All",
  });
  const [saasFilterOptions, setSaasFilterOptions] = useState([]);

  const handleExportMessage = (key, message) => {
    setExportMessage({ success: "", error: "", [key]: message });
    setTimeout(() => {
      setExportMessage({ success: "", error: "" });
    }, 3000);
  };

  const descriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link"],
    ],
  };

  const descriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const [show_all_saas_data, setShowAllSaasData] = useState(
    JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data
  );

  const [isEditSecSaas, setIsEditSecSaas] = useState(false);
  const [investmentManagersCSV, setInvestmentManagerCSV] = useState(null);

  const [investmentVehicles, setInvestmentVehicles] = useState([]);
  const [partnerSettingsError, setPartnerSettingsError] = useState([]);
  const [vpPayoutEnabledSyndicates, setVPPayoutEnabledSyndicates] = useState(
    []
  );

  const fetchAllInvestmentVehicles = async () => {
    try {
      let data = await getAllInvestmentVehicles();

      if (!data?.status) {
        setInvestmentVehicles([]);
        return;
      }

      setInvestmentVehicles(data?.investmentVehicles || []);
    } catch (error) {
      setInvestmentVehicles([]);
    }
  };

  const fetchVPPayoutEnabledSyndicates = async (req, res) => {
    try {
      let data = await getAllVPPayoutEnabledSyndicates();

      if (!data?.status) {
        setVPPayoutEnabledSyndicates([]);
        return;
      }

      setVPPayoutEnabledSyndicates(data?.vpPayoutEnabledSyndicates || []);
    } catch (error) {
      setVPPayoutEnabledSyndicates([]);
    }
  };

  const handleCSVfile = (e, key) => {
    console.log(e.target.files[0]);
    setInvestmentManagerCSV(e.target.files[0]);
  };

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    const loginUserInfo = JSON.parse(localStorage.getItem("authUser")).userInfo;
    setLoginUserData(loginUserInfo);

    fetchAllInvestmentVehicles();
    fetchVPPayoutEnabledSyndicates();

    if (JSON.parse(localStorage.getItem("roleWiseAccess"))?._id) {
      setAccess(JSON.parse(localStorage.getItem("roleWiseAccess")));
      setShowAllSaasData(
        JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data
      );
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(res => {
          setAccess(res.data.roleWiseAccess);
          setShowAllSaasData(res.data.roleWiseAccess?.show_all_saas_data);
        })
        .catch(error => {
          console.log("route-error", error?.message);
        });
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!modal) {
      fetchVPPayoutEnabledSyndicates();

      getAllUsersForReferenceEvenDisabled()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let {
                _id,
                firstname,
                lastname,
                email,
                saas_fullname,
                saas_name,
                saas_alias,
              } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                let temp2 = saas_name;
                if (temp2) {
                  temp2 += ` (${saas_fullname})`;
                } else {
                  temp2 = saas_fullname || saas_alias;
                }
                temp = temp2 || temp;

                if (user?.status == "Declined") {
                  saasPartners.push({
                    value: _id,
                    label: `${temp}*`,
                  });
                } else {
                  saasPartners.push({
                    value: _id,
                    label: temp,
                  });
                }
              }

              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          setReferenceUserList(users);
          // setSaasPartnersList(saasPartners);
          setSaasFilterOptions(saasPartners);
        })
        .catch(error => {
          console.trace(error);
          setReferenceUserList([]);
          // setSaasPartnersList([]);
          setSaasFilterOptions([]);
        });

      getDealManagers()
        .then(response => {
          let managers = response.managers;
          (managers = managers.sort((a, b) => {
            if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
              return -1;
            }
            if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
              return 1;
            }
            return 0;
          })),
            setinvestmentManagers(managers);

          managers = managers
            ?.filter(el => el?.status !== "Declined")
            ?.map(mngr => {
              return { value: mngr._id, label: mngr.name };
            });
          setinvestmentManagersFilter(managers);
        })
        .catch(error => {
          console.trace(error);
          setinvestmentManagers([]);
        });
    }
    return () => {};
  }, [modal]);

  useEffect(() => {
    if (!modal) {
      getAllUsersForReference()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let {
                _id,
                firstname,
                lastname,
                email,
                saas_fullname,
                saas_name,
                saas_alias,
              } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                let temp2 = saas_name;
                if (temp2) {
                  temp2 += ` (${saas_fullname})`;
                } else {
                  temp2 = saas_fullname || saas_alias;
                }
                temp = temp2 || temp;

                saasPartners.push({
                  value: _id,
                  label: temp,
                });
              }

              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          setSaasPartnersList(saasPartners);
        })
        .catch(error => {
          console.trace(error);
          setSaasPartnersList([]);
        });
    }
    return () => {};
  }, [modal]);

  useEffect(() => {
    if (!modal) {
      getAllPartnersForEmployees()
        .then(response => {
          let partners = response.data || [];
          partners = partners
            .map(partner => {
              if (!partner) return null;
              // let {
              //   _id: partnerId,
              //   user_id: {
              //     _id,
              //     userName,
              //     firstname,
              //     lastname,
              //     email,
              //     is_saas_parent,
              //     saas_fullname,
              //     saas_name,
              //     saas_alias,
              //   },
              // } = partner;
              let {
                _id,
                userName,
                firstname,
                lastname,
                email,
                is_saas_parent,
                saas_fullname,
                saas_name,
                saas_alias,
              } = partner;
              if (!_id) return null;
              let temp =
                `${firstname || ""} ${lastname || ""}`.trim() || userName;
              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }
              if (!temp) return null;

              if (is_saas_parent) {
                let temp2 = saas_fullname;
                if (temp2) {
                  temp2 += ` (${saas_name})`;
                } else {
                  temp2 = saas_alias;
                }
                temp = temp2 || temp;
              }

              return { value: _id, label: temp };
            })
            .filter(data => data);
          setAllPartners(partners);
        })
        .catch(error => {
          console.trace(error);
          setAllPartners([]);
        });
    }
    return () => {};
  }, [modal]);

  useEffect(() => {
    setLoading(true);
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let fmode = params.get("fmode");

    // const show_all_saas_data = JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data;
    if (searchQuery?.includes("createdAt")) {
      getUsersAll(
        `?page=${page}${searchQuery}&show_all_saas_data=${show_all_saas_data}`
      )
        .then(response => {
          let users = response.users.map(user => {
            if (user?.status?.toLowerCase() === "declined")
              user.status = "Disabled";
            return user;
          });
          setUsers(users);
          setTotal(response.dataSize);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
    return () => {};
  }, [page, load, searchQuery, show_all_saas_data]);

  useEffect(() => {
    if (!modal) {
      setCountry();
      setStateData();
      setCityData();
    }
    return () => {};
  }, [modal]);
  const [countryCurrencyCodes, setCountryCurrencyCodes] = useState([]);

  const [frontendAccessSettingRoles, setFrontendAccessSettingRoles] = useState(
    []
  );

  useEffect(() => {
    if (!allAccessSettings?.length) {
      getAllAccessSettingsFunction();
    } else {
      setFrontendAccessSettingRoles(
        allAccessSettings
          ?.sort((a, b) => {
            if (
              a?.setting_name?.toLowerCase() < b?.setting_name?.toLowerCase()
            ) {
              return -1;
            }
            if (
              a?.setting_name?.toLowerCase() > b?.setting_name?.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          })
          ?.map(setting => {
            return {
              value: setting?._id,
              label: setting?.setting_name,
            };
          }) || []
      );
    }

    return () => {};
  }, [allAccessSettings, getAllAccessSettingsFunction]);

  useEffect(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/generalmaster/getallcurrencies`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(response => {
          if (!response?.data?.status) {
            setCountryCurrencyCodes([]);
          }

          let { data } = response.data || {};
          setCountryCurrencyCodes(data);
        });
    } catch (error) {
      setCountryCurrencyCodes([]);
    }
    return () => {};
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/role/getroles`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      })
      .then(res => {
        let arr = [];
        res.data.roleWiseAccess?.map(data => {
          let obj = {};
          obj.label = data.name;
          obj.value = data._id;
          arr.push(obj);
        });
        setRoleWiseAccess(arr);
      })
      .catch(err => {
        // console.log(err)
      });
    return () => {};
  }, [selectedSaasPartner]);

  // to check saas-alias regex
  useEffect(() => {
    if (user.saas_alias !== "/") {
      if (!RegExp(/^[a-z0-9-]{1,}$/g).test(user.saas_alias)) {
        setSaasAliasError(
          "Invalid SAAS Alias, only small-case alphabets (a-z), numbers (0-9) and dash (-) allowed"
        );
      } else {
        setSaasAliasError(null);
      }
    } else {
      setSaasAliasError(null);
    }
    return () => {};
  }, [user]);

  const toggleActiveTab = activeTab => {
    setActiveTab(activeTab);
  };

  const isUserStrictlyFounder = () => {
    if (
      user?.user_types?.length === 1 &&
      user?.user_types[0]?.user_type[0] === "founder"
    ) {
      return true;
    }
    return false;
  };

  const uploadCSV = async () => {
    if (csvFile?.name !== undefined) {
      setLoading(true);
      setBtnDisable(true);
      const token = authHeader();
      const formData = new FormData();
      let configHeaders;
      if (token) {
        configHeaders = {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: token,
          },
        };
      }
      formData.append("csv_file", csvFile);
      formData.append("saas_parent_id", selectedSaas?.value);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/admin/user/import-users`,
          formData,
          configHeaders
        )
        .then(async response => {
          toast.success(response.data.message);
          document.getElementById("fileUpload").value = null;
          setPage(1);
        })
        .catch(error => {
          toast.error(error.response.data);
        });
      setLoading(false);
      setBtnDisable(false);
    }
  };

  const getCSVFile = (e, key) => {
    setCsvFile(e.target.files[0]);
  };

  const toggle = (isEdit = false, user = {}, satmode = 0) => {
    setDisplaySaasLogo("");
    setDisplaySaasFaviconLogo("");
    setDisplayPortfolioImage("");
    setCoreMembers([]);
    setProfileImages([]);
    if (!user?.intent_consent) {
      user.intent_consent = {
        base_on: "avg",
        isEquals: true,
        percent: 100,
      };
    }
    setUser(user);

    if (user?.saas_logo) setDisplaySaasLogo(user.saas_logo);
    if (user?.favicon_logo) setDisplaySaasFaviconLogo(user.favicon_logo);
    if (user?.portfolio_image) setDisplayPortfolioImage(user.portfolio_image);
    if (user?.core_members?.length) setDisplayCoreMembers(user.core_members);

    if (user.core_members && user.core_members.length > 0)
      setCoreMembers(user?.core_members);

    setisEdit(isEdit);
    setModal(!modal);
    setInvestment_manager_id(user?.investment_manager_id?._id);
    if (satmode) {
      setActiveTab("5");
    } else {
      setActiveTab("1");
    }
  };

  const handleMultiUserEdit = async () => {
    const token = authHeader();
    const formData = new FormData();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: token,
        },
      };
    }
    formData.append("csv_file", investmentManagersCSV);
    formData.append("investment_managers", JSON.stringify(investmentManagers));

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/admin/user/updateinvestmentmanagers`,
        formData,
        configHeaders
      )
      .then(async response => {
        toast.success(response.data.message);
        setInvestmentToggle(false);
        setInvestment_MID("");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
        // console.log(error)
        toast.error(error?.message);
      });
  };

  const handleDeleteUser = async user => {
    await Swal.fire({
      title: "Do you want to delete this user ?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (user._id !== undefined) {
          await deleteUser(user).then(() => {
            setLoad(prev => !prev);
          });
        }
      }
    });
  };
  const handleUserClickOld = user => {
    setIsEditSecSaas(false);
    handleUserClick(user, 0);
  };

  const handleUserClickSecSaas = user => {
    setIsEditSecSaas(true);
    handleUserClick(user, 1);
  };
  const handleUserClick = async (editUser, satmode = 0) => {
    let status = editUser.status === "Disabled" ? "Declined" : editUser.status;
    let isPartner = editUser?.user_types?.some(type =>
      ["partner", "partner employee"].includes(
        type?.user_type?.[0]?.toLowerCase()
      )
    );
    let currencyFlag = false;
    if (editUser?.currency) {
      let keys = Object.keys(editUser?.currency || {});
      if (keys.length === 4) {
        currencyFlag = Object.keys(editUser?.currency).every(
          key => editUser?.currency?.[key]
        );
      }
    }
    let currency = currencyFlag
      ? editUser?.currency
      : { label: "Indian Rupee", value: "INR", symbol: "₹", locale: "en-IN" };
    let saas_type = editUser?.saas_type || "Incubator - College";
    let syndicator_type = editUser?.syndicator_type || "public";
    editUser = {
      ...editUser,
      status,
      isPartner,
      currency,
      saas_type,
      syndicator_type,
    };
    setUser(editUser);
    if (editUser.core_members && editUser.core_members.length > 0)
      setCoreMembers(editUser?.core_members);

    let tempList = saasPartnersList.filter(user => user.value !== editUser._id);
    setSaasPartnersList(tempList);
    let refSaasPartner = editUser?.saas_parent_id?._id;
    let found = tempList.find(user => user.value === refSaasPartner);
    setSelectedSaasPartner(found);

    try {
      let response = await getUserAdditionalInfo(editUser._id);
      if (!response.status) {
        return alert(response.message || "User Info Not Found");
      }
      let userInfo = response.data || {};
      setUser({
        ...user,
        other_primary_saas: userInfo?.other_primary_saas || [],
      });
      let partnerUserInfo = userInfo["partner"] || {};
      delete userInfo["partner"];
      setIsAdmin(userInfo?.is_admin);
      setUserInfo(userInfo);
      setRoleId(userInfo?.role?._id);
      setPartnerUserInfo({
        ...partnerUserInfo,
        display_collection: [undefined, null, ""].includes(
          partnerUserInfo?.display_collection
        )
          ? true
          : partnerUserInfo?.display_collection,
        is_saas_parent: !!editUser?.is_saas_parent,
      });
      // setReferenceUserList(
      //   referenceUserList.filter(user => user.value !== editUser._id)
      // );
      // setAllPartners(allPartners.filter(user => user.value !== editUser._id));

      editUser = {
        ...editUser,
        isPartner,
      };

      let tempRefUser = {
        value: null,
        label: "Select User",
      };
      if (response.data?.invRefUserIds?.length) {
        let value = response.data?.invRefUserIds?.[0] || null;
        let label = referenceUserList.find(
          user => user?.value === value
        )?.label;

        if (!label) {
          value = null;
          label = "Select User";
        }
        tempRefUser = { value, label };
      }
      setSelectedRefUser(tempRefUser?.value ? tempRefUser : null);

      let tempRefPartner = {
        value: null,
        label: "Select Partner",
      };
      if (partnerUserInfo) {
        let value = partnerUserInfo?.reference_user_id?.[0] || null;
        if (value) {
          let label = allPartners.find(part => part?.value === value)?.label;
          if (!label) {
            value = null;
            label = "Select Partner";
          }
          tempRefPartner = { value, label };
        }
      }

      if (!isPartner) {
        let parentId = editUser?.is_saas_parent
          ? null
          : editUser?.saas_parent_id?._id;
        let parValue;
        if (parentId) {
          parValue = referenceUserList.find(user => user?.value === parentId);
        }
        setSelectedRefPartner(parValue ? parValue : null);
      } else {
        setSelectedRefPartner(tempRefPartner?.value ? tempRefPartner : null);
      }
      setPartnerType("partner");
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while fetching data";
      setUserInfo({});
      setPartnerUserInfo({});
      return alert(message);
    }

    toggle(true, editUser, satmode);
  };

  const handleUserLogin = async user => {
    let flag = await verifyAdminEmailOTP();

    if (!flag) {
      alert("Error while logging in to the user.");
      return;
    }

    //login admin as user
    let loggedUser;
    let url = process.env.REACT_APP_FRONTEND_URL;
    if (!url) {
      url = window.location.protocol + "//" + window.location.hostname;
    }

    try {
      loggedUser = await loginAsUser({ userId: user._id });
    } catch (err) {
      alert(
        err.response?.data?.message ||
          err.message ||
          "Error while logging in to the user"
      );
      return false;
    }

    if (loggedUser) {
      window.open(url + "/loginAsUser/" + loggedUser.token);
    }
  };

  const handleRangeFilterOnUser = (start, end) => {
    const filteredUsers = users.filter(user => {
      const createdAt = moment(new Date(user.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return user;
      } else {
        return;
      }
    });
    setUsers(filteredUsers);
  };

  const handleValidUserSubmit = async (e, values, tab) => {
    if (saasAliasError) {
      return alert(saasAliasError);
    }
    if (
      (isEmpty(investment_manager_id) || investment_manager_id === "null") &&
      activeTab === "2" &&
      user.is_saas_parent === true
    ) {
      return alert("Please Select Syndicate Manager");
    }

    if (isEdit) {
      let data = {
        id: user._id,
      };
      let intent_consent = user?.intent_consent || {};
      if (
        !user?.intent_consent?.base_on &&
        intent_consent?.isEquals &&
        intent_consent?.isEquals == true
      ) {
        intent_consent.base_on = "avg";
      }
      if (
        !user?.intent_consent?.percent &&
        intent_consent?.isEquals &&
        intent_consent?.isEquals == true
      ) {
        intent_consent.percent = 100;
      }
      if (intent_consent?.isEquals == false || !intent_consent?.isEquals) {
        intent_consent.base_on = "avg";
        intent_consent.percent = 100;
      }
      if (tab === "SAAS") {
        if (!user?.saas_type) {
          return alert("Please select Domain Expert Type");
        }

        if (loginUser?.is_super_admin && !user?.syndicator_type) {
          return alert("Please select Domain Expert type");
        }

        if (
          Object.values(user?.payout_settings || {}).some(
            value =>
              value &&
              (isNaN(value) || parseFloat(value) < 0 || parseFloat(value) > 100)
          )
        ) {
          return alert("Please enter payout setting values between 0-100");
        }

        let credits_per_lac_of_investments =
          user?.credits_per_lac_of_investments || {};

        let { for_self, for_referral } = credits_per_lac_of_investments;

        if (for_self && parseInt(for_self) < 0) {
          return alert(
            "Please enter credits for self investments greater than 0"
          );
        }

        if (for_referral && parseInt(for_referral) < 0) {
          return alert(
            "Please enter credits for referral investments greater than 0"
          );
        }
        if (intent_consent?.isEquals) {
          if (!intent_consent?.percent) {
            return alert(
              "Please enter intent equals to consent percentage value"
            );
          }
          if (
            intent_consent?.percent &&
            parseInt(intent_consent?.percent) <= 0
          ) {
            return alert(
              "Please enter valid intent equals to consent percentage value"
            );
          }
        }
        if (descriptionLength > 0 && descriptionLength <= 10) {
          return alert("Short Description should be more than 10 characters");
        }

        if (descriptionLength > 500) {
          return alert(
            "Short Description should not be more than 500 characters"
          );
        }

        if (portfolioLength > 0 && portfolioLength <= 10) {
          return alert("Portfolio should be more than 10 characters");
        }

        if (portfolioLength > 1500) {
          return alert("Portfolio should not be more than 1500 characters");
        }

        if (longDescriptionLength > 0 && longDescriptionLength <= 10) {
          return alert("Long Description should be more than 10 characters");
        }

        if (longDescriptionLength > 1500) {
          return alert(
            "Long Description should not be more than 1500 characters"
          );
        }
        if (coreMembers?.length) {
          let name = [],
            profile_pic = [],
            description = [];
          coreMembers.map(data => {
            name.push(data.name);
            profile_pic.push(data.profile_picture);
            description.push(data.description);
          });
          if (
            name.includes("") ||
            name.includes(undefined) ||
            name.includes(null) ||
            name.includes("undefined") ||
            profile_pic.includes("") ||
            profile_pic.includes(undefined) ||
            profile_pic.includes(null) ||
            profile_pic.includes("undefined") ||
            description.includes("") ||
            description.includes(undefined) ||
            description.includes(null) ||
            description.includes("undefined")
          )
            return alert(
              "enter all the details for a core team member or remove the member"
            );
        }

        let visible_startups = [];
        let visible_investors = [];

        if (user?.select_all_startup_saas) {
          visible_startups =
            saasPartnersList
              ?.map(saas => saas?.value?.toString())
              ?.filter(data => data) || [];
        } else {
          visible_startups = user?.visible_startups || [];
        }

        if (user?.select_all_investor_saas) {
          visible_investors =
            saasPartnersList
              ?.map(saas => saas?.value?.toString())
              ?.filter(data => data) || [];
        } else {
          visible_investors = user?.visible_investors || [];
        }

        data = {
          ...data,
          is_saas_parent: user.is_saas_parent,
          saas_parent_id: selectedSaasPartner?.value,
          secondary_saas_ids: (user?.secondary_saas_ids || []).join(","),
          saas_fullname: user.saas_fullname,
          saas_name: user.saas_name,
          saas_alias: user.saas_alias,
          saas_description: user.saas_description,
          saas_long_description: user.saas_long_description,
          saas_portfolio: user.saas_portfolio,
          core_members: JSON.stringify(coreMembers),
          // old_saas_logo: user.saas_logo,
          // old_portfolio_image: user.portfolio_image,
          // old_favicon_logo: user.favicon_logo,
          registration_credits: user?.registration_credits,
          subscription_fee: JSON.stringify(
            user?.subscription_fee || {
              annually_amount: 0,
              quarterly_amount: 0,
              per_day_deduction: 0,
              credit_limit: 0,
            }
          ),
          amounts: JSON.stringify(
            user?.amounts || {
              annually_amount: 0,
              quarterly_amount: 0,
            }
          ),
          credits_per_lac_of_investments: JSON.stringify(
            user?.credits_per_lac_of_investments
          ),
          currency: JSON.stringify(user?.currency),
          select_all_startup_saas: !!user?.select_all_startup_saas,
          visible_startups: visible_startups.join(","),
          select_all_investor_saas: !!user?.select_all_investor_saas,
          visible_investors: visible_investors.join(","),
          saas_type: user?.saas_type,
          syndicator_type: user?.syndicator_type,
          investment_manager_id: investment_manager_id,
          intent_consent: JSON.stringify(intent_consent),
          show_deal_data: user?.show_deal_data,
          // is_panellist: user?.is_panellist,
        };
      } else {
        data = {
          ...data,
          is_admin: values.is_admin,
          is_featured: values.is_featured,
          // is_panellist: values.is_panellist,
          is_super_admin: values.is_super_admin,
          status: values.status,
          roleId,
          reference_user_id: selectedRefUser?.value,
          is_saas_parent: user.is_saas_parent,
          saas_parent_id: selectedSaasPartner?.value,
          saas_fullname: user.saas_fullname,
          saas_name: user.saas_name,
          saas_alias: user.saas_alias,
          saas_description: user.saas_description,
          saas_long_description: user.saas_long_description,
          saas_portfolio: user.saas_portfolio,
          core_members: JSON.stringify(coreMembers),
          // profile_images: JSON.stringify(profileImages),
          // old_saas_logo: user.saas_logo,
          // old_portfolio_image: user.portfolio_image,
          // old_favicon_logo: user.favicon_logo,
          registration_credits: user?.registration_credits,
          subscription_fee: JSON.stringify(
            user?.subscription_fee || {
              annually_amount: 0,
              quarterly_amount: 0,
              per_day_deduction: 0,
              credit_limit: 0,
            }
          ),
          amounts: JSON.stringify(
            user?.amounts || {
              annually_amount: 0,
              quarterly_amount: 0,
            }
          ),
          credits_per_lac_of_investments: JSON.stringify(
            user?.credits_per_lac_of_investments
          ),
          currency: JSON.stringify(user?.currency),
          saas_type: user?.saas_type,
          syndicator_type: user?.syndicator_type,
          investment_manager_id: investment_manager_id,
          intent_consent: JSON.stringify(intent_consent),
          show_deal_data: user?.show_deal_data,
        };
      }

      if (displaySaasLogo) {
        data["saas_logo"] = displaySaasLogo;
      }
      // if (displaySaasFaviconLogo) {
      data["favicon_logo"] = displaySaasFaviconLogo;
      // } else data["favicon_logo"] = "";
      // if (displayPortfolioImage) {
      data["portfolio_image"] = displayPortfolioImage;
      // } else data["portfolio_image"] = "";
      if (displayCoreMembers.length) {
        displayCoreMembers.map((cm, i) => {
          if (cm?.profile_picture) {
            data[`profile_image_${i}`] = cm.profile_picture;
          }
        });
      }

      data = {
        ...data,
        payout_settings: JSON.stringify(user?.payout_settings || {}),
        vp_payout_enabled: !!user?.vp_payout_enabled,
        frontendSettingId: user?.frontendSettingId,
      };

      const token = authHeader();

      const userData = JSON.stringify({
        userId: user._id,
        city_text: userInfo?.userCityText,
        state_text: userInfo?.userStateText,
        country_text: userInfo?.userCountryText,
        city_id: cityData?.value || userInfo?.userCityId,
        state_id: stateData?.value || userInfo?.userStateId,
        country_id: country?.value || userInfo?.userCountryId,
        reference_user_text: userInfo?.invRefUserText,
        is_saas_parent: data.is_saas_parent,
        investment_manager_id: investment_manager_id,
        professionalData: {
          states: stateData?.value,
          city: cityData?.label,
          country: country?.value,
        },
        // is_panellist: data.is_panellist
      });

      let encryptedQuery = encryptDataToArray(userData);
      let queryUserData = {
        q: JSON.stringify(encryptedQuery),
      };

      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/api/admin/user/update-user-info`,
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        data: queryUserData,
      };

      return await axios(config)
        .then(async response => {
          if (!response?.data?.status) {
            return alert(
              response?.data?.message || "Error while updating user info"
            );
          }

          await updateUser(data).then(() => {
            setLoad(prev => !prev);
            setUser({});
            toggle();
          });
        })
        .catch(error => {
          //console.log(error);
          return alert(
            error?.response?.data?.message ||
              error?.message ||
              "Error while updating user info"
          );
        });
    } else {
      const newUser = {
        firstname: values["firstname"],
        lastname: values["lastname"],
        mobile_country_code: values["mobile_country_code"],
        mobile_number: values["mobile_number"],
        alternateMobileNumber: values["alternateMobileNumber"],
        email: values["email"],
        linkedin_profile_link: values["linkedin_profile_link"],
        is_admin: values["is_admin"],
        mobile_number_verified: values["mobile_number_verified"],
        email_verified: values["email_verified"],
        status: values["status"],
        password: values["password"],
        confirm_pass: values["confirm_pass"],
        user_types: values["user_types"],
      };
      // save new user
      return await addNewUser(newUser).then(() => {
        setPage(1);
        toggle();
      });
    }
  };

  const defaultSorted = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const handleInputCheck = (e, item) => {
    // console.log(e.target.checked, e.target.checked === false)
    console.log("USERLIST====>", userllistIMS);
    const isChecked = e.target.checked;
    let newSelectedItems;

    if (isChecked) {
      // Add the item to the selected items array
      newSelectedItems = [...userllistIMS, item];
    } else {
      // Remove the item from the selected items array
      newSelectedItems = userllistIMS.filter(
        selectedItem => selectedItem?._id !== item?._id
      );
    }

    userllistIMS = newSelectedItems;
    setUserListIM(newSelectedItems);
  };

  const userListColumns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    // {
    //   dataField: "Select",
    //   editable: false,
    //   text: "Select",
    //   headerStyle: (colum, colIndex) => {
    //     return { width: '70px', textAlign: 'center' };
    //   },
    //   formatter: (cellContent, user) => (
    //     <div className="d-flex justify-content-center">
    //       {/* {console.log("==1212==>", !!userllistIMS?.filter((itm) => itm?._id == user?._id)?.length)} */}
    //       <Input
    //         type="checkbox"
    //         size={"md"}
    //         defaultChecked={!!userllistIMS?.filter((itm) => itm?._id == user?._id)?.length}
    //         onChange={(e) => handleInputCheck(e, user)}
    //       />
    //     </div>
    //   ),
    // },
    {
      text: "Name",
      dataField: "firstname",
      sort: true,
      filterValue: (cell, user) => `${user.firstname} ${user.lastname}`,
      filter: textFilter({
        defaultValue: history.location.search.split("&firstname=")[1],
        delay: 1500,
      }),
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <div className="fw-bold">
              {user.firstname} {user.lastname}
            </div>
          </h5>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: textFilter({
        defaultValue: history.location.search.split("&email=")[1],
        delay: 1500,
      }),
    },
    {
      dataField: "is_admin",
      text: "Is Admin ",
      hidden: true,
      sort: true,
      formatter: (cellContent, user) => (
        <>{user.is_admin.charAt(0).toUpperCase() + user.is_admin.slice(1)}</>
      ),
      //
    },
    {
      dataField: "saas_parent_id",
      text: "Primary Domain Expert",
      sort: false,
      formatter: (cellContent, user) => {
        let value = user?.saas_parent_id?.saas_name;

        if (user?.is_saas_parent) {
          value = `Parent - ${user?.saas_name}`;
        }
        return <>{value}</>;
      },
      filter:
        loginUser?.is_super_admin || show_all_saas_data
          ? selectFilter({
              options: saasFilterOptions.sort((a, b) => {
                if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                  return -1;
                }
                if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                  return 1;
                }
                return 0;
              }),
              delay: 1500,
            })
          : false,
    },
    {
      dataField: "other_primary_saas",
      text: "All Domain Expert",
      sort: false,
      formatter: (cellContent, user) => {
        let value = [];
        let k = 0;
        if (user?.saas_parent_id) {
          value.push(user?.saas_parent_id?.saas_name);
        }
        if (user?.is_saas_parent) {
          value.push(user?.saas_name);
        }
        if (user?.other_primary_saas?.length > 0) {
          user?.other_primary_saas?.map((e, index) => {
            if (
              e._id &&
              user?.other_primary_saas_info?.find(
                item => item?.saas_parent_id === e._id
              )?.status !== "rejected"
            ) {
              value.push(e.saas_name);
              return 1;
            }
          });
        }
        value = value?.filter(data => !!data).join(", ");
        return (
          <span title={value}>
            {value?.length > 15 ? value?.slice(0, 16) + "..." : value}
          </span>
        );
      },
      filter:
        loginUser?.is_super_admin || show_all_saas_data
          ? selectFilter({
              options: saasFilterOptions.sort((a, b) => {
                if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                  return -1;
                }
                if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                  return 1;
                }
                return 0;
              }),
              delay: 1500,
            })
          : false,
    },
    {
      dataField: "user_types[0].user_type[0]",
      text: "User Type",
      sort: false,
      formatter: (cellContent, user) => (
        <div className="text-capitalize">
          {user?.user_types
            ?.map(userType => userType?.user_type?.[0] || "")
            .filter(data => !!data)
            .join(", ")}
        </div>
      ),
      filter: textFilter({
        delay: 1500,
      }),
    },
    {
      text: "IM/DEM",
      dataField: "investment_manager_id",
      sort: true,
      filter: selectFilter({
        options: investmentManagersFilter,
        delay: 1500,
      }),
      formatter: (cellContent, user) => {
        let value = [];
        let investment_manager_name = user?.investment_manager_id?.firstname;
        if (investment_manager_name && user?.investment_manager_id?.lastname) {
          investment_manager_name =
            investment_manager_name +
            " " +
            user?.investment_manager_id?.lastname;
        }

        if (investment_manager_name) {
          value.push(investment_manager_name);
        }

        let syndicate_manager_name =
          user?.saas_parent_id?.syndicate_manager_detail?.firstname;

        if (
          syndicate_manager_name &&
          user?.saas_parent_id?.syndicate_manager_detail?.lastname
        ) {
          syndicate_manager_name =
            syndicate_manager_name +
            " " +
            user?.saas_parent_id?.syndicate_manager_detail?.lastname;
        }
        if (syndicate_manager_name) {
          value.push(syndicate_manager_name);
        }
        value = value.toString();
        return <>{value}</>;
      },
    },
    // {
    //   dataField: "totalPastEventAttended",
    //   text: "Events Attended",
    //   sort: false,
    //   // sort: false,
    //   formatter: (cellContent, user) => (
    //     <>
    //       {user.totalPastEventAttended
    //         ? `${user.totalPastEventAttended} [${user.totalDuration} mins]`
    //         : user.totalPastEventAttended}
    //     </>
    //   ),
    //   // filter: textFilter(),
    // },
    // {
    //   dataField: "totalPastInvestments",
    //   text: "Past Investments",
    //   sort: false,
    //   // sort: false,
    //   formatter: (cellContent, user) => (
    //     <>
    //       {user.totalPastInvestments
    //         ? `${user.totalPastInvestments} [${user?.totalInvestedAmount}]`
    //         : user.totalPastInvestments}
    //     </>
    //   ),
    //   // filter: textFilter(),
    // },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, user) => {
        if (user?.status?.toLowerCase() === "declined") return <>Disabled</>;
        return (
          <span style={{ whiteSpace: "nowrap" }} className="text-capitalize">
            {user?.status}
          </span>
        );
      },
      filter: textFilter({
        delay: 1500,
      }),
    },
    {
      dataField: "email_verified",
      text: "Email Verified",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {user.email_verified.charAt(0).toUpperCase() +
            user.email_verified.slice(1)}
        </>
      ),
      filter: textFilter({
        delay: 1500,
      }),
      hidden: true,
    },
    {
      dataField: "mobile_number_verified",
      text: "Mobile number Verified",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {user.mobile_number_verified.charAt(0).toUpperCase() +
            user.mobile_number_verified.slice(1)}
        </>
      ),
      filter: textFilter({
        delay: 1500,
      }),
      hidden: true,
    },
    {
      dataField: "user_verified",
      text: "User verified",
      sort: false,
      formatter: (cellContent, user) => <>{user.user_verified}</>,
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, user) => (
        <>{moment(new Date(user.createdAt)).format("DD MMM Y")}</>
      ),
      // csvFormatter: (cellContent,user) => `${moment(new Date(user.createdAt)).format("DD MMM Y")}`,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: `${
        access?.userListLoginAction ||
        access?.userListEditAction ||
        access?.show_secondary_saas_details
          ? "Action"
          : ""
      }`,
      csvExport: false,
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          {access?.userListLoginAction ? (
            <Link className="text-success" to="#" title="Login with this user">
              <i
                className="mdi mdi-share font-size-18"
                id="logintooltip"
                onClick={() => handleUserLogin(user)}
              ></i>
            </Link>
          ) : (
            ""
          )}

          {access?.userListEditAction ? (
            <Link className="text-success" to="#" title="Edit">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => handleUserClickOld(user)}
              ></i>
            </Link>
          ) : (
            ""
          )}

          {access?.show_secondary_saas_details &&
          user?.other_primary_saas?.length &&
          user?.other_primary_saas[0]?._id ? (
            <Link
              className="text-success"
              to="#"
              title="Secondary Syndicate Details"
            >
              <i
                className="mdi mdi-puzzle-edit font-size-18"
                onClick={() => handleUserClickSecSaas(user)}
              ></i>
            </Link>
          ) : (
            ""
          )}

          {/* <Link
            className="text-success"
            to={`/saas-payout/${user._id}`}
            title="View Saas Payout Details"
          >
            <i className="mdi mdi-eye font-size-18" id="saastip"></i>
          </Link> */}

          {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteUser(user)}
            ></i>
          </Link> */}
          {/* <Link
            className="text-success"
            to={
              user.user_types[0]?.user_type[0] === "founder"
                ? `/founders-detail/${user._id}`
                : user.user_types[0]?.user_type[0] === "investor"
                ? `/investor-details/${user._id}`
                : `/professional-detail/${user._id}`
            }
          >
            <i color="primary" className="font-16 btn-block btn btn-primary">
              View Details
            </i>
          </Link> */}
        </div>
      ),
    },
  ];

  const onTableChange = async (action, { filters, sortOrder, sortField }) => {
    let str = "";
    Object.keys(filters).forEach(filter => {
      let filterVal = filters?.[filter]?.filterVal;
      if (filterVal) {
        switch (filter) {
          case "user_types[0].user_type[0]":
            str += `&user_type=${filterVal}`;
            break;
          case "status":
            str += `&status=${
              "disabled".startsWith(filterVal.toLowerCase())
                ? "Declined"
                : filterVal
            }`;
            break;
          default:
            str += `&${filter}=${filterVal}`;
            break;
        }
      }
    });
    let order = sortOrder === "asc" ? 1 : -1;
    str += `&sort=${
      sortField === "_id" ? "createdAt" : sortField
    }&order=${order}`;
    setSearchQuery(str);
  };

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
    },
  };

  const selectRole = e => {
    setRoleId(e.value);
  };

  const downloadDemoCsv = () => {
    const demoCsv = [
      [
        "FirstName",
        "LastName",
        "Email",
        "PhoneNumber",
        "City",
        "UserType",
        "LinkedInUrl",
        "Investment Allocated For Year",
        "Reference",
      ],
      [
        "Bob",
        "John",
        "BoB@yopmail.com",
        9090090909,
        "Chicago",
        "investor",
        "https://www.linkedin.com/in/name",
        "upto $25,000",
        "Xyz",
      ],
      [
        "Peeter",
        "John",
        "peeter@yopmail.com",
        9876543210,
        "USA",
        "investor",
        "https://www.linkedin.com/in/name",
        "upto $25,000",
        "Bob",
      ],
    ];

    setDemoCSVExportData(demoCsv);
  };

  const downloadInvestmentManagerCsv = () => {
    const demoCsv = [
      ["Email", "IM"],
      ["BoB@yopmail.com", "example1@mailinator.com"],
      ["peeter@yopmail.com", "example2@mailinator.com"],
    ];
    setInvestmentCSVExportData(demoCsv);
    setTimeout(() => {
      setInvestmentCSVExportData([]);
    }, 1);
  };

  const handleSubmitExportUsers = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      let resBody = {};
      resBody["admin_email"] = loginUser?.email;
      resBody["type"] = exportType;
      if (loginUser.is_super_admin) {
        resBody["sass_parent_id"] = selectedSaasExport.value;
      } else if (show_all_saas_data) {
        resBody["sass_parent_id"] = selectedSaasExport.value;
      } else {
        resBody["sass_parent_id"] = loginUser.saas_parent_id;
      }
      let response = await saveUserExportMail(resBody);
      if (response) {
        let { status, message, data } = response;
        message = message || "Error in creating user list";
        handleExportMessage(status ? "success" : "error", message);
        if (status) {
          setTimeout(() => {
            setExportAdminMail("");
          }, 3000);
        }
      } else {
        handleExportMessage("error", "Error in creating user list");
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error in creating user list";
      handleExportMessage("error", message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPartnerUserInfo = async () => {
    try {
      let reqBody = {};
      reqBody["isPartner"] = !!user?.isPartner;

      if (!!user?.isPartner) {
        if (!partnerUserInfo?.is_saas_parent && !selectedRefPartner?.value) {
          return alert("Please Select Reference Partner");
        }

        if (
          partnerSettingsError?.some(settingsError =>
            Object.keys(settingsError?.settings || {})?.some(
              key => !!settingsError?.settings?.[key]
            )
          )
        ) {
          return;
        }

        if (
          partnerUserInfo?.is_saas_parent ||
          (partnerUserInfo?.can_get_payout &&
            !partnerUserInfo?.apply_parent_settings)
        ) {
          let level_1_settings = partnerUserInfo?.level_1_settings || {};
          let { subscription_fee, carry_fee } = level_1_settings;

          if (subscription_fee) {
            let { amount } = subscription_fee;
            if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
              return alert("Please enter subscription fee % between 0-100");
            }
          }

          if (carry_fee) {
            let { amount } = carry_fee;
            if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
              return alert("Please enter carry fee % between 0-100");
            }
          }
        }

        reqBody["partnerType"] = partnerType || "partner";
        reqBody["level_1_settings"] = partnerUserInfo?.level_1_settings;
        reqBody["vp_settings"] = partnerUserInfo?.vp_settings;
        reqBody["refPartnerId"] = selectedRefPartner?.value || null;
        // reqBody["see_all_startups"] = partnerUserInfo?.see_all_startups;
        // reqBody["see_all_investors"] = partnerUserInfo?.see_all_investors;
        reqBody["credits_per_lac_of_investments"] =
          partnerUserInfo?.credits_per_lac_of_investments;
        reqBody["transaction_fee_disc_self_investments"] =
          partnerUserInfo?.transaction_fee_disc_self_investments;
        reqBody["display_collection"] = partnerUserInfo?.display_collection;
        reqBody["display_commision"] = partnerUserInfo?.display_commision;
        reqBody["can_get_payout"] = partnerUserInfo?.can_get_payout;
        reqBody["apply_parent_settings"] =
          partnerUserInfo?.apply_parent_settings;
        reqBody["vp_individual"] = partnerUserInfo?.vp_individual;
        reqBody["vp_company"] = partnerUserInfo?.vp_company;
        reqBody["vp_syndicate"] = partnerUserInfo?.vp_syndicate;
        reqBody["vp_syndicate_subuser"] = partnerUserInfo?.vp_syndicate_subuser;
        reqBody["account_details"] = partnerUserInfo?.account_details;
      }

      let response = await updatePartnerUserInfoUserList(user._id, reqBody);

      if (!response?.status) {
        return alert(response?.message || "Cannot update partner info");
      }

      setPartnerUserInfo({});
      setSelectedRefPartner(null);
      setPartnerType("partner");
      toggle();
      setLoad(!load);
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating partner user info";
      return alert(message);
    }
  };

  const removeImage = async (id, logo) => {
    try {
      if (confirm("Are you sure want to delete the Favicon?")) {
        const token = authHeader();
        let configHeaders;
        if (token) {
          configHeaders = {
            headers: {
              "Content-Type": `multipart/form-data`,
              Authorization: token,
            },
          };
        }
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/admin/user/removeFavicon/${id}`,
            configHeaders
          )
          .then(async response => {
            // setSavedFavicon(false);
            setUser(prevState => ({
              ...prevState,
              favicon_logo: null,
            }));
          })
          .catch(error => {
            // console.log(error);
          });
      }
      return false;
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating partner user info";
      return alert(message);
    }
  };
  const isPartnerEditDisabled = (() => {
    let isPartner = !!user?.isPartner;
    return !isPartner;
  })();

  const isUserDisabled = ["Disabled", "Declined"].includes(user?.status);
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Users List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-0">
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={userListColumns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={users}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="justify-content-between">
                              <Col md="6">
                                <h5 className="text-uppercase">Users List </h5>
                                <div className="ms-2 d-inline-block">
                                  {access.importUsers == true ? (
                                    <Form className="mb-1">
                                      <div className="d-flex mt-1 align-items-center">
                                        <div className="mx-1">
                                          <input
                                            type="file"
                                            accept=".csv"
                                            id="fileUpload"
                                            className="form-control"
                                            onChange={(e, key) =>
                                              getCSVFile(e, key)
                                            }
                                          />
                                        </div>
                                        <div className="ms-1">
                                          <div>
                                            {demoCsvExportData.length > 0 ? (
                                              <CSVDownload
                                                data={demoCsvExportData}
                                                target="_blank"
                                              />
                                            ) : null}
                                          </div>
                                          <div
                                            onClick={downloadDemoCsv}
                                            style={{
                                              color: "#485ec4",
                                              cursor: "pointer",
                                            }}
                                          >
                                            Reference for user import format
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex mt-1 align-items-center">
                                        {loginUser?.is_super_admin && (
                                          <>
                                            <div className="mx-1 w-50">
                                              <Select
                                                placeholder={
                                                  "Select Domain Expert Parent"
                                                }
                                                options={saasPartnersList}
                                                onChange={setSelectedSaas}
                                                value={selectedSaas}
                                                required={!user?.is_saas_parent}
                                              />
                                            </div>
                                          </>
                                        )}

                                        {csvFile.name !== undefined &&
                                        selectedSaas.value !== null ? (
                                          <>
                                            <div className="mx-1">
                                              <Button
                                                className="btn btn-info"
                                                onClick={uploadCSV}
                                                disabled={
                                                  btnDisable ? true : false
                                                }
                                              >
                                                <i className="bx bx-import fs-4"></i>{" "}
                                                Import CSV
                                              </Button>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="mx-1">
                                              <Button
                                                className="btn btn-info"
                                                onClick={uploadCSV}
                                                disabled
                                              >
                                                <i className="bx bx-import fs-4"></i>{" "}
                                                Import CSV
                                              </Button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </Form>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Col>
                              {/* <Col md="2"></Col> */}
                              <Col md="6">
                                <div className="ms-2">
                                  {access.exportUsers == true ||
                                  access.founderExport ? (
                                    <Form
                                      className="mb-1"
                                      onSubmit={handleSubmitExportUsers}
                                    >
                                      <div className="d-flex mt-1 align-items-center justify-content-end">
                                        <div className="mx-1 w-50 d-none">
                                          <Input
                                            type="email"
                                            name="adminMail"
                                            required
                                            disabled
                                            placeholder="Enter Email to send list to*"
                                            // value={exportAdminMail}
                                            value={loginUser?.email}
                                            onChange={e =>
                                              setExportAdminMail(e.target.value)
                                            }
                                          />
                                        </div>
                                        {(loginUser?.is_super_admin ||
                                          show_all_saas_data) && (
                                          <>
                                            <div
                                              className="ms-1"
                                              style={{ minWidth: 150 }}
                                            >
                                              <Select
                                                placeholder={
                                                  "Select Syndicate Parent"
                                                }
                                                options={[
                                                  {
                                                    label: "All",
                                                    value: "all",
                                                  },
                                                  ...saasPartnersList,
                                                ]}
                                                onChange={setSelectedSaasExport}
                                                value={selectedSaasExport}
                                                required={!user?.is_saas_parent}
                                              />
                                            </div>
                                          </>
                                        )}
                                        {access.exportUsers == true ? (
                                          <Button
                                            type="submit"
                                            color="primary"
                                            onClick={() => {
                                              setExportType("all");
                                            }}
                                            className="mx-1"
                                          >
                                            User Export CSV
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                        {access.founderExport == true ? (
                                          <Button
                                            type="submit"
                                            color="success"
                                            onClick={() => {
                                              setExportType("aws-founders");
                                            }}
                                            className="mx-1"
                                          >
                                            Founder Export CSV
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                        {access?.edit_investment_manager && (
                                          <Button
                                            type="submit"
                                            color="secondary"
                                            onClick={e => {
                                              e.preventDefault();
                                              setInvestmentToggle(true);
                                            }}
                                            className="mx-1"
                                          >
                                            Edit Investment Manager
                                          </Button>
                                        )}
                                      </div>
                                      <div className="d-flex mt-1 align-items-center">
                                        <div className="investmentManagersList">
                                          <Modal
                                            isOpen={investmentToggle}
                                            toggle={() => {
                                              setInvestmentToggle(false);
                                              setInvestment_MID("");
                                              setInvestmentCSVExportData([]);
                                            }}
                                            size="lg"
                                          >
                                            <ModalHeader
                                              toggle={() =>
                                                setInvestmentToggle(false)
                                              }
                                              tag="h4"
                                            >
                                              Select Investment Manager
                                            </ModalHeader>
                                            <ModalBody>
                                              <Row>
                                                <Col sm={12} className="mb-2">
                                                  <div
                                                    style={{
                                                      maxHeight: "400px",
                                                      overflowY: "auto",
                                                    }}
                                                  >
                                                    <input
                                                      type="file"
                                                      accept=".csv,.xlsx"
                                                      id="fileUpload"
                                                      className="form-control"
                                                      onChange={(e, key) =>
                                                        handleCSVfile(e, key)
                                                      }
                                                    />
                                                    <div>
                                                      <div>
                                                        {demoInvestmentExportData.length >
                                                        0 ? (
                                                          <CSVDownload
                                                            data={
                                                              demoInvestmentExportData
                                                            }
                                                            target="_blank"
                                                          />
                                                        ) : null}
                                                      </div>
                                                      <div
                                                        className="mt-2"
                                                        style={{
                                                          color: "#485ec4",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <span
                                                          onClick={
                                                            downloadInvestmentManagerCsv
                                                          }
                                                        >
                                                          Reference for user
                                                          import format
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <Row className="mt-3 mb-3 align-items-center">
                                                      <Col md={3} sm={4} xs={4}>
                                                        <label className="">
                                                          Investment Manager
                                                          List:
                                                        </label>
                                                      </Col>
                                                      <Col>
                                                        <select
                                                          className="form-control"
                                                          // onChange={e => {
                                                          //   e.preventDefault();
                                                          //   setInvestment_MID(
                                                          //     e.target.value
                                                          //   );
                                                          // }}
                                                        >
                                                          {investmentManagers &&
                                                            investmentManagers.length >
                                                              0 &&
                                                            investmentManagers.map(
                                                              (val, key) => (
                                                                <option
                                                                  key={key}
                                                                  value={
                                                                    val._id
                                                                  }
                                                                  selected={
                                                                    user
                                                                      .investment_manager_id
                                                                      ?._id ===
                                                                    val._id
                                                                  }
                                                                >
                                                                  {" "}
                                                                  {val?.name}
                                                                  {" - "}
                                                                  {val?.email}
                                                                </option>
                                                              )
                                                            )}
                                                        </select>
                                                      </Col>
                                                    </Row>
                                                    <div className="text-center">
                                                      <Button
                                                        color="success"
                                                        onClick={() =>
                                                          handleMultiUserEdit()
                                                        }
                                                      >
                                                        Update
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </ModalBody>
                                          </Modal>
                                        </div>
                                      </div>
                                      <div className="d-flex mt-1 align-items-center">
                                        <div className="mx-1">
                                          {!!exportMessage.success && (
                                            <Label className="text-success">
                                              {exportMessage.success}
                                            </Label>
                                          )}
                                          {!!exportMessage.error && (
                                            <Label className="text-danger">
                                              {exportMessage.error}
                                            </Label>
                                          )}
                                        </div>
                                      </div>
                                    </Form>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                    rowStyle={row => ({
                                      backgroundColor: row?.is_saas_parent
                                        ? "lightgray"
                                        : "",
                                      fontWeight: row?.is_saas_parent
                                        ? "600"
                                        : "0",
                                    })}
                                  />
                                  <Modal
                                    size="lg"
                                    isOpen={modal}
                                    toggle={() => toggle()}
                                  >
                                    <ModalHeader
                                      toggle={() => toggle()}
                                      tag="h4"
                                    >
                                      {isEdit ? "Edit User" : "Add User"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <div>
                                        <Nav tabs>
                                          {!isEditSecSaas && (
                                            <NavItem>
                                              <NavLink
                                                className={
                                                  activeTab === "1" && "active"
                                                }
                                                onClick={() => {
                                                  toggleActiveTab("1");
                                                }}
                                              >
                                                User Details
                                              </NavLink>
                                            </NavItem>
                                          )}

                                          {!isEditSecSaas && (
                                            <NavItem>
                                              <NavLink
                                                className={
                                                  activeTab === "4" && "active"
                                                }
                                                onClick={() => {
                                                  toggleActiveTab("4");
                                                }}
                                              >
                                                Admin Details
                                              </NavLink>
                                            </NavItem>
                                          )}
                                          {!isEditSecSaas && (
                                            <NavItem>
                                              <NavLink
                                                className={
                                                  activeTab === "2" && "active"
                                                }
                                                onClick={() => {
                                                  toggleActiveTab("2");
                                                }}
                                                disabled={isUserDisabled}
                                              >
                                                Domain Expert Details
                                              </NavLink>
                                            </NavItem>
                                          )}

                                          {!!user?.other_primary_saas?.length &&
                                            isEditSecSaas && (
                                              <NavItem>
                                                <NavLink
                                                  className={
                                                    activeTab === "5" &&
                                                    "active"
                                                  }
                                                  onClick={() => {
                                                    toggleActiveTab("5");
                                                  }}
                                                  disabled={isUserDisabled}
                                                >
                                                  Secondary Syndicate Details
                                                </NavLink>
                                              </NavItem>
                                            )}

                                          {!isEditSecSaas &&
                                            user?.user_types?.some(
                                              type =>
                                                type?.user_type?.[0].toLowerCase() ===
                                                "investor"
                                            ) && (
                                              <NavItem>
                                                <NavLink
                                                  className={
                                                    activeTab === "3" &&
                                                    "active"
                                                  }
                                                  onClick={() => {
                                                    toggleActiveTab("3");
                                                  }}
                                                  disabled={isUserDisabled}
                                                >
                                                  Partner Details
                                                </NavLink>
                                              </NavItem>
                                            )}
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                          <TabPane tabId="1">
                                            <AvForm
                                              onValidSubmit={
                                                handleValidUserSubmit
                                              }
                                              model={user}
                                            >
                                              <Row form className="mt-3">
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <label>Status</label>
                                                    <AvRadioGroup
                                                      inline
                                                      name="status"
                                                      required
                                                    >
                                                      <AvRadio
                                                        label="Pending"
                                                        value="Pending"
                                                      />
                                                      <AvRadio
                                                        label="Approved"
                                                        value="Approved"
                                                      />
                                                      <AvRadio
                                                        label="Disabled"
                                                        value="Declined"
                                                      />
                                                    </AvRadioGroup>
                                                  </div>

                                                  {/* <div className="mb-3">
                                                    <label>Is Admin</label>
                                                    <AvRadioGroup
                                                      inline
                                                      name="is_admin"
                                                      required
                                                      onChange={e => {
                                                        setIsAdmin(
                                                          e.target.value
                                                        );
                                                      }}
                                                      disabled={isUserDisabled}
                                                    >
                                                      <AvRadio
                                                        label="Yes"
                                                        value="true"
                                                        disabled={
                                                          loginUser.is_super_admin ==
                                                          false &&
                                                          loginUser.is_saas_parent ==
                                                          false
                                                        }
                                                      />
                                                      <AvRadio
                                                        label="No"
                                                        value="false"
                                                        disabled={
                                                          loginUser.is_super_admin ==
                                                          false &&
                                                          loginUser.is_saas_parent ==
                                                          false
                                                        }
                                                      />
                                                    </AvRadioGroup>
                                                  </div> */}

                                                  {/* <div className="mb-3">
                                                    <Select
                                                      id="select"
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                      placeholder={
                                                        "----Select Role----"
                                                      }
                                                      options={roleWiseAccess.sort(
                                                        (a, b) => {
                                                          if (
                                                            a?.label?.toLowerCase() <
                                                            b?.label?.toLowerCase()
                                                          ) {
                                                            return -1;
                                                          }
                                                          if (
                                                            a?.label?.toLowerCase() >
                                                            b?.label?.toLowerCase()
                                                          ) {
                                                            return 1;
                                                          }
                                                          return 0;
                                                        }
                                                      )}
                                                      onChange={selectRole}
                                                      defaultValue={
                                                        userInfo?.role?._id
                                                          ? {
                                                            value:
                                                              userInfo?.role
                                                                ?._id,
                                                            label:
                                                              userInfo?.role
                                                                ?.name,
                                                          }
                                                          : ""
                                                      }
                                                      isDisabled={
                                                        isUserDisabled ||
                                                        (loginUser.is_super_admin ==
                                                          false &&
                                                          loginUser.is_saas_parent ==
                                                          false) ||
                                                        is_admin === "false"
                                                      }
                                                    />
                                                  </div> */}

                                                  <div className="mb-2">
                                                    <AvGroup check>
                                                      <AvInput
                                                        type="checkbox"
                                                        name="is_featured"
                                                        value={
                                                          user.is_featured ||
                                                          false
                                                        }
                                                        disabled={
                                                          isUserDisabled
                                                        }
                                                      />
                                                      <label>
                                                        {" "}
                                                        Is Featured
                                                      </label>
                                                    </AvGroup>
                                                  </div>
                                                  {/* <div className="mb-3">
                                                    <AvGroup check>
                                                      <AvInput
                                                        type="checkbox"
                                                        name="is_panellist"
                                                        value={
                                                          user?.is_panellist ||
                                                          false
                                                        }
                                                        disabled={
                                                          isUserDisabled
                                                        }
                                                      />
                                                      <label>
                                                        {" "}
                                                        Is Panellist
                                                      </label>
                                                    </AvGroup>
                                                  </div> */}

                                                  {/* {loginUser.is_super_admin ===
                                                    true && (
                                                      <div className="mb-3">
                                                        <AvGroup check>
                                                          <AvInput
                                                            type="checkbox"
                                                            name="is_super_admin"
                                                            value={
                                                              user.is_super_admin ||
                                                              false
                                                            }
                                                            disabled={
                                                              isUserDisabled
                                                            }
                                                          />
                                                          <label>
                                                            {" "}
                                                            Is Super Admin
                                                          </label>
                                                        </AvGroup>
                                                      </div>
                                                    )}

                                                  {is_admin === "true" ? (
                                                    <Row>
                                                      <Col sm="6">
                                                        {!!isEdit ? null : (
                                                          <div className="mb-3">
                                                            <AvField
                                                              name="password"
                                                              label="Password"
                                                              type="password"
                                                              errorMessage="Invalid password"
                                                              validate={{
                                                                required: {
                                                                  value: true,
                                                                },
                                                              }}
                                                              value=""
                                                            />
                                                          </div>
                                                        )}
                                                      </Col>
                                                      <Col sm="6">
                                                        {!!isEdit ? null : (
                                                          <div className="mb-3">
                                                            <AvField
                                                              name="confirm_pass"
                                                              label="Confirm Password"
                                                              type="password"
                                                              errorMessage="Invalid password"
                                                              validate={{
                                                                required: {
                                                                  value: true,
                                                                },
                                                              }}
                                                              value=""
                                                            />
                                                          </div>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {!!isEdit ? null : (
                                                    <div className="mb-3">
                                                      <label>User Type</label>
                                                      <AvRadioGroup
                                                        inline
                                                        name="user_types"
                                                        required
                                                      >
                                                        <AvRadio
                                                          label="Investor"
                                                          value="investor"
                                                        />
                                                        <AvRadio
                                                          label="Founder"
                                                          value="founder"
                                                        />
                                                        <AvRadio
                                                          label="Individual"
                                                          value="individual"
                                                        />
                                                        <AvRadio
                                                          label="Invstt"
                                                          value="wfc"
                                                        />
                                                      </AvRadioGroup>
                                                    </div>
                                                  )} */}

                                                  <div className="mb-3">
                                                    <div
                                                      inline
                                                      name="status"
                                                      required
                                                    >
                                                      <Row>
                                                        <Col>
                                                          <label className="">
                                                            Country:
                                                          </label>
                                                          <input
                                                            type="text"
                                                            name="country_text"
                                                            value={
                                                              userInfo?.userCountryText
                                                            }
                                                            className="form-control"
                                                            disabled
                                                          />
                                                          <CountryData
                                                            className="mt-1"
                                                            name="country"
                                                            placeholder="Select"
                                                            selected={
                                                              country?.value
                                                                ? country?.value
                                                                : userInfo?.userCountryId
                                                            }
                                                            onChange={
                                                              setCountry
                                                            }
                                                            isDisabled={
                                                              isUserDisabled
                                                            }
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <label className="">
                                                            State:
                                                          </label>
                                                          <input
                                                            type="text"
                                                            name="state_text"
                                                            value={
                                                              userInfo?.userStateText
                                                            }
                                                            className="form-control"
                                                            disabled
                                                          />
                                                          <StateData
                                                            className="mt-1"
                                                            name="state"
                                                            placeholder="Select"
                                                            countryCode={
                                                              country?.value
                                                                ? country?.value
                                                                : userInfo?.userCountryId
                                                            }
                                                            selected={
                                                              stateData?.value
                                                                ? stateData?.value
                                                                : userInfo?.userStateId
                                                            }
                                                            onChange={
                                                              setStateData
                                                            }
                                                            isDisabled={
                                                              isUserDisabled
                                                            }
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <label className="">
                                                            City:
                                                          </label>
                                                          <input
                                                            type="text"
                                                            name="city_text"
                                                            value={
                                                              userInfo?.userCityText
                                                            }
                                                            className="form-control"
                                                            disabled
                                                          />
                                                          <CityData
                                                            className="mt-1"
                                                            name="city"
                                                            placeholder="Select"
                                                            countryCode={
                                                              country?.value
                                                                ? country?.value
                                                                : userInfo?.userCountryId
                                                            }
                                                            stateCode={
                                                              stateData?.value
                                                                ? stateData?.value
                                                                : userInfo?.userStateId
                                                            }
                                                            selected={
                                                              cityData?.value
                                                                ? cityData?.value
                                                                : userInfo?.userCityId
                                                            }
                                                            onChange={
                                                              setCityData
                                                            }
                                                            isDisabled={
                                                              isUserDisabled
                                                            }
                                                          />
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </div>

                                                  {user?.user_types?.some(
                                                    type =>
                                                      type?.user_type?.[0].toLowerCase() ===
                                                      "investor"
                                                  ) && (
                                                    <div className="mb-3">
                                                      <div
                                                        inline
                                                        name="status"
                                                        required
                                                      >
                                                        <Row>
                                                          <Col>
                                                            <label className="">
                                                              Reference Name:
                                                            </label>
                                                            <input
                                                              type="text"
                                                              name="country_text"
                                                              value={
                                                                userInfo?.invRefUserText
                                                              }
                                                              className="form-control"
                                                              disabled
                                                            />
                                                            <Select
                                                              className="mt-1"
                                                              placeholder={
                                                                "Select User"
                                                              }
                                                              options={referenceUserList
                                                                .filter(
                                                                  userLists =>
                                                                    userLists.value !==
                                                                    user._id
                                                                )
                                                                .sort(
                                                                  (a, b) => {
                                                                    if (
                                                                      a?.label?.toLowerCase() <
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return -1;
                                                                    }
                                                                    if (
                                                                      a?.label?.toLowerCase() >
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return 1;
                                                                    }
                                                                    return 0;
                                                                  }
                                                                )}
                                                              onChange={
                                                                setSelectedRefUser
                                                              }
                                                              value={
                                                                selectedRefUser
                                                              }
                                                              isClearable
                                                              isDisabled={
                                                                isUserDisabled ||
                                                                user?.is_saas_parent ||
                                                                user?.isPartner
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  )}

                                                  {user?.user_types?.some(
                                                    type =>
                                                      type?.user_type?.[0].toLowerCase() ===
                                                        "founder" &&
                                                      userInfo?.foundRefUserText
                                                  ) && (
                                                    <div className="mb-3">
                                                      <div
                                                        inline
                                                        name="status"
                                                        required
                                                      >
                                                        <Row>
                                                          <Col>
                                                            <label className="">
                                                              Reference Name:
                                                            </label>
                                                            <input
                                                              type="text"
                                                              name="country_text"
                                                              value={
                                                                userInfo?.foundRefUserText
                                                              }
                                                              className="form-control"
                                                              disabled
                                                            />
                                                            <Select
                                                              className="mt-1"
                                                              placeholder={
                                                                "Select User"
                                                              }
                                                              options={referenceUserList
                                                                .filter(
                                                                  userLists =>
                                                                    userLists.value !==
                                                                    user._id
                                                                )
                                                                .sort(
                                                                  (a, b) => {
                                                                    if (
                                                                      a?.label?.toLowerCase() <
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return -1;
                                                                    }
                                                                    if (
                                                                      a?.label?.toLowerCase() >
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return 1;
                                                                    }
                                                                    return 0;
                                                                  }
                                                                )}
                                                              onChange={
                                                                setSelectedRefUser
                                                              }
                                                              value={
                                                                selectedRefUser
                                                              }
                                                              isClearable
                                                              isDisabled={
                                                                isUserDisabled ||
                                                                user?.is_saas_parent ||
                                                                user?.isPartner
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  )}

                                                  <Row className="mb-3 align-items-center">
                                                    <Col>
                                                      <label>Currency:</label>
                                                      <Select
                                                        id="currencyCode"
                                                        required={
                                                          !!user?.is_saas_parent
                                                        }
                                                        placeholder="Select Currency"
                                                        options={
                                                          countryCurrencyCodes
                                                        }
                                                        value={user?.currency}
                                                        name="Currency"
                                                        onChange={value => {
                                                          setUser({
                                                            ...user,
                                                            currency: value,
                                                          });
                                                        }}
                                                      />
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <label className="">
                                                    Source:
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name="source"
                                                    value={user?.source}
                                                    className="form-control"
                                                    disabled
                                                  />
                                                </Col>
                                              </Row>

                                              {!user?.is_saas_parent ? (
                                                <>
                                                  <Row className="mt-3 mb-3 align-items-center">
                                                    <Col md={3} sm={4} xs={4}>
                                                      <label className="">
                                                        Investment Manager{" "}
                                                        {isUserStrictlyFounder()
                                                          ? ""
                                                          : "*"}
                                                        :
                                                      </label>
                                                    </Col>
                                                    <Col>
                                                      <select
                                                        className="form-control"
                                                        onChange={e => {
                                                          setInvestment_manager_id(
                                                            e.target.value
                                                          );
                                                        }}
                                                      >
                                                        <option value={"null"}>
                                                          --Select Investment
                                                          Manager--
                                                        </option>
                                                        {investmentManagers &&
                                                          investmentManagers.length >
                                                            0 &&
                                                          investmentManagers.map(
                                                            (val, key) => (
                                                              <option
                                                                key={key}
                                                                value={val._id}
                                                                selected={
                                                                  user
                                                                    .investment_manager_id
                                                                    ?._id ===
                                                                  val._id
                                                                }
                                                              >
                                                                {" "}
                                                                {val.name}
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                    </Col>
                                                  </Row>
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              <Row>
                                                {/* <Col xl="12">
                                                  {!isEmpty(error) ? (
                                                    <p className="text-danger">
                                                      Some error occured!
                                                    </p>
                                                  ) : null}
                                                </Col> */}
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </AvForm>
                                          </TabPane>

                                          <TabPane tabId="4">
                                            <AvForm
                                              onValidSubmit={
                                                handleValidUserSubmit
                                              }
                                              model={user}
                                            >
                                              <Row form className="mt-3">
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <label>Is Admin</label>
                                                    <AvRadioGroup
                                                      inline
                                                      name="is_admin"
                                                      required
                                                      onChange={e => {
                                                        setIsAdmin(
                                                          e.target.value
                                                        );
                                                      }}
                                                      disabled={isUserDisabled}
                                                    >
                                                      <AvRadio
                                                        label="Yes"
                                                        value="true"
                                                        disabled={
                                                          loginUser.is_super_admin ==
                                                            false &&
                                                          loginUser.is_saas_parent ==
                                                            false
                                                        }
                                                      />
                                                      <AvRadio
                                                        label="No"
                                                        value="false"
                                                        disabled={
                                                          loginUser.is_super_admin ==
                                                            false &&
                                                          loginUser.is_saas_parent ==
                                                            false
                                                        }
                                                      />
                                                    </AvRadioGroup>
                                                  </div>

                                                  <div className="mb-3">
                                                    <label>
                                                      Select Admin Role
                                                    </label>
                                                    <Select
                                                      id="select"
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                      placeholder={
                                                        "----Select Role----"
                                                      }
                                                      options={roleWiseAccess.sort(
                                                        (a, b) => {
                                                          if (
                                                            a?.label?.toLowerCase() <
                                                            b?.label?.toLowerCase()
                                                          ) {
                                                            return -1;
                                                          }
                                                          if (
                                                            a?.label?.toLowerCase() >
                                                            b?.label?.toLowerCase()
                                                          ) {
                                                            return 1;
                                                          }
                                                          return 0;
                                                        }
                                                      )}
                                                      onChange={selectRole}
                                                      defaultValue={
                                                        userInfo?.role?._id
                                                          ? {
                                                              value:
                                                                userInfo?.role
                                                                  ?._id,
                                                              label:
                                                                userInfo?.role
                                                                  ?.name,
                                                            }
                                                          : ""
                                                      }
                                                      isDisabled={
                                                        isUserDisabled ||
                                                        (loginUser.is_super_admin ==
                                                          false &&
                                                          loginUser.is_saas_parent ==
                                                            false) ||
                                                        is_admin === "false"
                                                      }
                                                    />
                                                  </div>

                                                  <div className="mb-3">
                                                    <label>
                                                      Select Frontend Role
                                                    </label>
                                                    <Select
                                                      id="select"
                                                      placeholder="Select Frontend Role"
                                                      options={
                                                        frontendAccessSettingRoles
                                                      }
                                                      isClearable
                                                      onChange={value => {
                                                        setUser({
                                                          ...user,
                                                          frontendSettingId:
                                                            value?.value,
                                                        });
                                                      }}
                                                      value={frontendAccessSettingRoles?.find(
                                                        setting =>
                                                          setting?.value ===
                                                          user?.frontendSettingId
                                                      )}
                                                      isDisabled={
                                                        isUserDisabled ||
                                                        loginUser.is_super_admin ==
                                                          false ||
                                                        is_admin === "false"
                                                      }
                                                    />
                                                  </div>

                                                  {/* <div className="mb-3">
                                                    <AvGroup check>
                                                      <AvInput
                                                        type="checkbox"
                                                        name="is_featured"
                                                        value={
                                                          user.is_featured ||
                                                          false
                                                        }
                                                        disabled={
                                                          isUserDisabled
                                                        }
                                                      />
                                                      <label>
                                                        {" "}
                                                        Is Featured
                                                      </label>
                                                    </AvGroup>
                                                  </div> */}

                                                  {loginUser.is_super_admin ===
                                                    true && (
                                                    <div className="mb-3">
                                                      <AvGroup check>
                                                        <AvInput
                                                          type="checkbox"
                                                          name="is_super_admin"
                                                          value={
                                                            user.is_super_admin ||
                                                            false
                                                          }
                                                          disabled={
                                                            isUserDisabled
                                                          }
                                                        />
                                                        <label>
                                                          {" "}
                                                          Is Super Admin
                                                        </label>
                                                      </AvGroup>
                                                    </div>
                                                  )}

                                                  {is_admin === "true" ? (
                                                    <Row>
                                                      <Col sm="6">
                                                        {!!isEdit ? null : (
                                                          <div className="mb-3">
                                                            <AvField
                                                              name="password"
                                                              label="Password"
                                                              type="password"
                                                              errorMessage="Invalid password"
                                                              validate={{
                                                                required: {
                                                                  value: true,
                                                                },
                                                              }}
                                                              value=""
                                                            />
                                                          </div>
                                                        )}
                                                      </Col>
                                                      <Col sm="6">
                                                        {!!isEdit ? null : (
                                                          <div className="mb-3">
                                                            <AvField
                                                              name="confirm_pass"
                                                              label="Confirm Password"
                                                              type="password"
                                                              errorMessage="Invalid password"
                                                              validate={{
                                                                required: {
                                                                  value: true,
                                                                },
                                                              }}
                                                              value=""
                                                            />
                                                          </div>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {!!isEdit ? null : (
                                                    <div className="mb-3">
                                                      <label>User Type</label>
                                                      <AvRadioGroup
                                                        inline
                                                        name="user_types"
                                                        required
                                                      >
                                                        <AvRadio
                                                          label="Investor"
                                                          value="investor"
                                                        />
                                                        <AvRadio
                                                          label="Founder"
                                                          value="founder"
                                                        />
                                                        <AvRadio
                                                          label="Individual"
                                                          value="individual"
                                                        />
                                                        <AvRadio
                                                          label="Invstt"
                                                          value="wfc"
                                                        />
                                                      </AvRadioGroup>
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </AvForm>
                                          </TabPane>

                                          <TabPane tabId="2">
                                            <AvForm
                                              onValidSubmit={(e, values) =>
                                                handleValidUserSubmit(
                                                  e,
                                                  values,
                                                  "SAAS"
                                                )
                                              }
                                              model={user}
                                            >
                                              <Row form className="mt-3">
                                                <Col className="col-12">
                                                  <Row className="mb-3">
                                                    <Col>
                                                      <Form1.Check
                                                        type="switch"
                                                        label="Is Domain Expert Parent ?"
                                                        checked={
                                                          user?.is_saas_parent
                                                        }
                                                        onChange={event => {
                                                          setUser({
                                                            ...user,
                                                            is_saas_parent:
                                                              event.target
                                                                .checked,
                                                          });
                                                        }}
                                                      />
                                                    </Col>
                                                  </Row>

                                                  {user?.is_saas_parent && (
                                                    <>
                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert Type{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Select
                                                            placeholder={
                                                              "Select Domain Expert Type"
                                                            }
                                                            options={saasTypesList.sort(
                                                              (a, b) => {
                                                                if (
                                                                  a?.label?.toLowerCase() <
                                                                  b?.label?.toLowerCase()
                                                                ) {
                                                                  return -1;
                                                                }
                                                                if (
                                                                  a?.label?.toLowerCase() >
                                                                  b?.label?.toLowerCase()
                                                                ) {
                                                                  return 1;
                                                                }
                                                                return 0;
                                                              }
                                                            )}
                                                            onChange={value => {
                                                              setUser({
                                                                ...user,
                                                                saas_type:
                                                                  value?.value,
                                                              });
                                                            }}
                                                            value={saasTypesList.find(
                                                              type =>
                                                                type?.value ===
                                                                user?.saas_type
                                                            )}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      {loginUser?.is_super_admin && (
                                                        <Row className="mb-3 align-items-center">
                                                          <Col
                                                            md={3}
                                                            sm={4}
                                                            xs={4}
                                                            className="mt-2 mt-sm-0"
                                                          >
                                                            <Label>
                                                              Domain Expert Type{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </Label>
                                                          </Col>
                                                          <Col
                                                            md={9}
                                                            sm={8}
                                                            xs={8}
                                                            className="mt-2 mt-sm-0"
                                                          >
                                                            <Row>
                                                              {syndicateTypeList?.map(
                                                                type => {
                                                                  return (
                                                                    <Col
                                                                      xs={4}
                                                                      sm={4}
                                                                      md={3}
                                                                      lg={2}
                                                                    >
                                                                      <Form1.Check
                                                                        id={
                                                                          type.value
                                                                        }
                                                                        name={
                                                                          type.value
                                                                        }
                                                                        type="radio"
                                                                        inline
                                                                        label={
                                                                          type.label
                                                                        }
                                                                        checked={
                                                                          type.value ===
                                                                          user?.syndicator_type
                                                                        }
                                                                        onChange={event => {
                                                                          if (
                                                                            event
                                                                              .target
                                                                              .checked
                                                                          ) {
                                                                            setUser(
                                                                              {
                                                                                ...user,
                                                                                syndicator_type:
                                                                                  type.value,
                                                                              }
                                                                            );
                                                                          }
                                                                        }}
                                                                      />
                                                                    </Col>
                                                                  );
                                                                }
                                                              )}
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                      )}

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert Full
                                                            Name{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Input
                                                            type="text"
                                                            name="saas_fullname"
                                                            value={
                                                              user?.saas_fullname
                                                            }
                                                            className="form-control"
                                                            onChange={event => {
                                                              setUser({
                                                                ...user,
                                                                saas_fullname:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                            required={
                                                              !!user?.is_saas_parent
                                                            }
                                                          />
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert Short
                                                            Name{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Input
                                                            type="text"
                                                            name="saas_name"
                                                            value={
                                                              user?.saas_name
                                                            }
                                                            className="form-control"
                                                            onChange={event => {
                                                              setUser({
                                                                ...user,
                                                                saas_name:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                            required={
                                                              !!user?.is_saas_parent
                                                            }
                                                          />
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert Alias{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Input
                                                            type="text"
                                                            name="saas_alias"
                                                            value={
                                                              user?.saas_alias
                                                            }
                                                            pattern={
                                                              /^[a-z0-9-]{0,}$/g
                                                            }
                                                            className="form-control"
                                                            onChange={event => {
                                                              setUser({
                                                                ...user,
                                                                saas_alias:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                            required={
                                                              !!user?.is_saas_parent
                                                            }
                                                          />
                                                          {saasAliasError && (
                                                            <span className="text-danger">
                                                              <small>
                                                                {saasAliasError}
                                                              </small>
                                                            </span>
                                                          )}
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center justify-content-end gap-5">
                                                        {/* <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Syndicate Logo{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label> 
                                                        </Col>*/}
                                                        <Col
                                                          md={4}
                                                          // sm={8}
                                                          // xs={8}
                                                          className="mt-2 mt-sm-0 imgbor"
                                                        >
                                                          <DragDropRounded
                                                            file="syndicate_logo"
                                                            titleText={
                                                              "Domain Expert Logo*"
                                                            }
                                                            prevId={`syndicateLogoPrev`}
                                                            src={
                                                              typeof displaySaasLogo ===
                                                              "string"
                                                                ? displaySaasLogo
                                                                : displaySaasLogo
                                                                ? URL.createObjectURL(
                                                                    displaySaasLogo
                                                                  )
                                                                : ""
                                                            }
                                                            setFile={({
                                                              file,
                                                            }) => {
                                                              setDisplaySaasLogo(
                                                                file[0]
                                                              );
                                                            }}
                                                          />
                                                          <small>
                                                            Recommended size:
                                                            170 * 60
                                                          </small>
                                                          <button
                                                            className="btn-del"
                                                            onClick={e => {
                                                              e.preventDefault();
                                                              setDisplaySaasLogo(
                                                                ""
                                                              );
                                                            }}
                                                          >
                                                            -
                                                          </button>

                                                          {/* <Label>
                                                            Syndicate Logo{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0 imgbor"
                                                        >
                                                          <Input
                                                            type="file"
                                                            accept=".jpeg, .png, .jpg, .gif"
                                                            className="form-control"
                                                            placeholder="Select Logo"
                                                            onChange={event => {
                                                              setPartnerLogo({
                                                                fileName:
                                                                  event.target
                                                                    .value,
                                                                file: event
                                                                  .target
                                                                  .files[0],
                                                              });
                                                            }}
                                                            multiple={false}
                                                            required={
                                                              !!user?.is_saas_parent
                                                            }
                                                          />
                                                          {user?.saas_logo && (
                                                            <>
                                                              <a
                                                                href={
                                                                  !user?.is_saas_parent
                                                                    ? null
                                                                    : user?.saas_logo
                                                                }
                                                                target="_blank"
                                                              >
                                                                Saved Logo
                                                              </a>
                                                            </>
                                                          )} */}
                                                        </Col>
                                                        <Col
                                                          md={4}
                                                          // sm={8}
                                                          // xs={8}
                                                          className="mt-2 mb-3 mt-sm-0 imgbor"
                                                        >
                                                          <DragDropRounded
                                                            file="favicon_logo"
                                                            titleText={
                                                              "Favicon Logo"
                                                            }
                                                            prevId={`faviconLogoPrev`}
                                                            src={
                                                              typeof displaySaasFaviconLogo ===
                                                              "string"
                                                                ? displaySaasFaviconLogo
                                                                : displaySaasFaviconLogo
                                                                ? URL.createObjectURL(
                                                                    displaySaasFaviconLogo
                                                                  )
                                                                : ""
                                                            }
                                                            setFile={({
                                                              file,
                                                            }) => {
                                                              setDisplaySaasFaviconLogo(
                                                                file[0]
                                                              );
                                                            }}
                                                          />
                                                          <button
                                                            className="btn-del"
                                                            onClick={e => {
                                                              e.preventDefault();
                                                              setDisplaySaasFaviconLogo(
                                                                ""
                                                              );
                                                            }}
                                                          >
                                                            -
                                                          </button>
                                                          {/* <Input
                                                            type="file"
                                                            accept=".jpeg, .png, .jpg, .gif"
                                                            className="form-control"
                                                            placeholder="Select Favicon Logo"
                                                            onChange={event => {
                                                              setFaviconLogo({
                                                                fileName:
                                                                  event.target
                                                                    .value,
                                                                file: event
                                                                  .target
                                                                  .files[0],
                                                              });
                                                            }}
                                                            multiple={false}
                                                            required={
                                                              !!user?.is_saas_parent
                                                            }
                                                          />
                                                          {typeof user?.favicon_logo !==
                                                            "undefined" &&
                                                            user?.favicon_logo !==
                                                              null &&
                                                            user.favicon_logo !==
                                                              "null" &&
                                                            user.favicon_logo
                                                              ?.length > 0 && (
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                <>
                                                                  <a
                                                                    href={
                                                                      user?.favicon_logo
                                                                    }
                                                                    target="_blank"
                                                                  >
                                                                    Saved
                                                                    Favicon
                                                                  </a>
                                                                  <span
                                                                    className="text-danger"
                                                                    onClick={() => {
                                                                      removeImage(
                                                                        user._id,
                                                                        user?.favicon_logo
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        logoLightSvg
                                                                      }
                                                                    />
                                                                  </span>
                                                                </>
                                                              </div>
                                                            )} */}
                                                        </Col>
                                                      </Row>

                                                      {/* <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0 imgbor"
                                                        >
                                                          <Label>
                                                            Favicon Logo{" "}
                                                            <span className="text-danger"></span>
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <DragDropRounded
                                                            file="syndicate_logo"
                                                            titleText={
                                                              "Favicon Logo"
                                                            }
                                                            prevId={`faviconLogoPrev`}
                                                            src={
                                                              typeof displaySaasFaviconLogo ===
                                                              "string"
                                                                ? displaySaasFaviconLogo
                                                                : displaySaasFaviconLogo
                                                                ? URL.createObjectURL(
                                                                    displaySaasFaviconLogo
                                                                  )
                                                                : ""
                                                            }
                                                            onChange={event => {
                                                              setFaviconLogo({
                                                                fileName:
                                                                  event.target
                                                                    .value,
                                                                file: event
                                                                  .target
                                                                  .files[0],
                                                              });
                                                            }}
                                                            setFile={({
                                                              file,
                                                            }) => {
                                                              setDisplaySaasFaviconLogo(
                                                                file[0]
                                                              );
                                                            }}
                                                          />
                                                          <button
                                                            className="btn-del"
                                                            style={{
                                                              top: "10px",
                                                              right: "150px",
                                                            }}
                                                            onClick={e => {
                                                              e.preventDefault();
                                                              setDisplaySaasFaviconLogo(
                                                                ""
                                                              );
                                                              setFaviconLogo({
                                                                fileName: null,
                                                                file: null,
                                                              });
                                                            }}
                                                          >
                                                            -
                                                          </button>
                                                          
                                                        </Col>
                                                      </Row> */}

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert Short
                                                            Description
                                                            {/* <span className="text-danger">
                                                              *
                                                            </span> */}
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <ReactQuill
                                                            id="description"
                                                            className="minh"
                                                            placeholder=""
                                                            value={
                                                              user?.saas_description ||
                                                              ""
                                                            }
                                                            onChange={data => {
                                                              let regex =
                                                                /<[^>]+>/g;
                                                              const matches =
                                                                data.match(
                                                                  regex
                                                                );
                                                              let totalLength = 0;
                                                              if (matches) {
                                                                for (let match of matches) {
                                                                  totalLength +=
                                                                    match.length;
                                                                }
                                                              }
                                                              let lengthOfCharacters =
                                                                data.length -
                                                                totalLength;
                                                              setUser({
                                                                ...user,
                                                                saas_description:
                                                                  data,
                                                              });
                                                              setDescriptionLength(
                                                                lengthOfCharacters
                                                              );
                                                            }}
                                                            modules={
                                                              descriptionEditorModules
                                                            }
                                                            formats={
                                                              descriptionEditorFormats
                                                            }
                                                          />
                                                          <small>
                                                            Note: minimum 10
                                                            characters required.
                                                          </small>
                                                          <br />
                                                          <small
                                                            style={
                                                              500 -
                                                                descriptionLength <=
                                                              10
                                                                ? {
                                                                    color:
                                                                      "red",
                                                                  }
                                                                : {}
                                                            }
                                                          >
                                                            {500 -
                                                              descriptionLength <
                                                            0
                                                              ? `Please reduce the text.`
                                                              : `Remaining characters: ${
                                                                  500 -
                                                                  descriptionLength
                                                                }`}
                                                          </small>
                                                        </Col>
                                                      </Row>

                                                      <Row
                                                        className="mb-3"
                                                        style={{
                                                          marginTop:
                                                            "3.75rem; position:relative;",
                                                        }}
                                                      >
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                        >
                                                          <Label>
                                                            Core Members
                                                          </Label>
                                                          {/* <span className="text-danger">
                                                            *
                                                          </span> */}
                                                        </Col>

                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                        >
                                                          {coreMembers.map(
                                                            (cm, index) => {
                                                              return (
                                                                <>
                                                                  <div className="d-flex align-items-start core-mana">
                                                                    <div className="w-100">
                                                                      <Row className="mb-3 align-items-center">
                                                                        <Col
                                                                          md={3}
                                                                          sm={4}
                                                                          xs={4}
                                                                          className="mt-2 mt-sm-0"
                                                                        >
                                                                          <Label>
                                                                            Profile
                                                                            Picture{" "}
                                                                            <span className="text-danger">
                                                                              *
                                                                            </span>
                                                                          </Label>
                                                                        </Col>

                                                                        <Col
                                                                          md={9}
                                                                          sm={8}
                                                                          xs={8}
                                                                          className="mt-2 mt-sm-0 imgbor"
                                                                        >
                                                                          <DragDropRounded
                                                                            file={`proflie_picture_${index}`}
                                                                            titleText={
                                                                              "Profile Picture"
                                                                            }
                                                                            prevId={`profilePicture${index}`}
                                                                            src={
                                                                              typeof displayCoreMembers?.[
                                                                                index
                                                                              ]
                                                                                ?.profile_picture ===
                                                                              "string"
                                                                                ? displayCoreMembers[
                                                                                    index
                                                                                  ]
                                                                                    ?.profile_picture
                                                                                : displayCoreMembers[
                                                                                    index
                                                                                  ]
                                                                                    ?.profile_picture
                                                                                ? URL.createObjectURL(
                                                                                    displayCoreMembers[
                                                                                      index
                                                                                    ]
                                                                                      ?.profile_picture
                                                                                  )
                                                                                : ""
                                                                            }
                                                                            setFile={({
                                                                              file,
                                                                            }) => {
                                                                              let allCoreMembers =
                                                                                [
                                                                                  ...displayCoreMembers,
                                                                                ];
                                                                              let valueAtIndex =
                                                                                allCoreMembers[
                                                                                  index
                                                                                ];
                                                                              valueAtIndex.profile_picture =
                                                                                file[0];
                                                                              allCoreMembers[
                                                                                index
                                                                              ] =
                                                                                valueAtIndex;

                                                                              setDisplayCoreMembers(
                                                                                allCoreMembers
                                                                              );
                                                                            }}
                                                                          />
                                                                          <button
                                                                            className="btn-del"
                                                                            onClick={e => {
                                                                              e.preventDefault();
                                                                              let allCoreMembers =
                                                                                [
                                                                                  ...displayCoreMembers,
                                                                                ];
                                                                              let valueAtIndex =
                                                                                allCoreMembers[
                                                                                  index
                                                                                ];
                                                                              valueAtIndex.profile_picture =
                                                                                "";
                                                                              allCoreMembers[
                                                                                index
                                                                              ] =
                                                                                valueAtIndex;

                                                                              setDisplayCoreMembers(
                                                                                allCoreMembers
                                                                              );
                                                                            }}
                                                                          >
                                                                            -
                                                                          </button>
                                                                          {/* <Input
                                                                            type="file"
                                                                            accept=".jpeg, .png, .jpg, .gif"
                                                                            className="form-control"
                                                                            placeholder="Select Profile Picture"
                                                                            // value={
                                                                            //   cm?.profile_picture ||
                                                                            //   ""
                                                                            // }
                                                                            src={
                                                                              cm?.profile_picture ||
                                                                              ""
                                                                            }
                                                                            onChange={event => {
                                                                              let allProfileImages =
                                                                                [
                                                                                  ...profileImages,
                                                                                ];
                                                                              let valueAtIndex =
                                                                                allProfileImages[
                                                                                  index
                                                                                ];
                                                                              valueAtIndex =
                                                                                {
                                                                                  fileName:
                                                                                    event
                                                                                      .target
                                                                                      .value,
                                                                                  file: event
                                                                                    .target
                                                                                    .files[0],
                                                                                };
                                                                              allProfileImages[
                                                                                index
                                                                              ] =
                                                                                valueAtIndex;
                                                                              setProfileImages(
                                                                                allProfileImages
                                                                              );
                                                                            }}
                                                                            multiple={
                                                                              false
                                                                            }
                                                                            // required={
                                                                            //   !!user?.is_saas_parent
                                                                            // }
                                                                          />
                                                                          {cm?.profile_picture && (
                                                                            <>
                                                                              <a
                                                                                href={
                                                                                  !user?.is_saas_parent
                                                                                    ? null
                                                                                    : cm?.profile_picture
                                                                                }
                                                                                target="_blank"
                                                                              >
                                                                                Saved
                                                                                picture
                                                                              </a>
                                                                            </>
                                                                          )} */}
                                                                        </Col>
                                                                      </Row>
                                                                      <Row className="mb-3 align-items-center">
                                                                        <Col
                                                                          md={3}
                                                                          sm={4}
                                                                          xs={4}
                                                                          className="mt-2 mt-sm-0"
                                                                        >
                                                                          <Label>
                                                                            Name{" "}
                                                                            <span className="text-danger">
                                                                              *
                                                                            </span>
                                                                          </Label>
                                                                        </Col>
                                                                        <Col
                                                                          md={9}
                                                                          sm={8}
                                                                          xs={8}
                                                                          className="mt-2 mt-sm-0"
                                                                        >
                                                                          <Input
                                                                            type="text"
                                                                            name="saas_core_member_name"
                                                                            value={
                                                                              cm?.name
                                                                            }
                                                                            // pattern={
                                                                            //   /^[a-z0-9-]{0,}$/g
                                                                            // }
                                                                            className="form-control"
                                                                            onChange={event => {
                                                                              let updatedCoreMembers =
                                                                                [
                                                                                  ...coreMembers,
                                                                                ];
                                                                              let valueAtIndex =
                                                                                updatedCoreMembers[
                                                                                  index
                                                                                ];
                                                                              valueAtIndex.name =
                                                                                event.target.value;
                                                                              updatedCoreMembers[
                                                                                index
                                                                              ] =
                                                                                valueAtIndex;
                                                                              setCoreMembers(
                                                                                updatedCoreMembers
                                                                              );
                                                                            }}
                                                                            // required={
                                                                            //   !!user?.is_saas_parent
                                                                            // }
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                      <Row className="mb-3 align-items-center">
                                                                        <Col
                                                                          md={3}
                                                                          sm={4}
                                                                          xs={4}
                                                                          className="mt-2 mt-sm-0"
                                                                        >
                                                                          <Label>
                                                                            Description{" "}
                                                                            <span className="text-danger">
                                                                              *
                                                                            </span>
                                                                          </Label>
                                                                        </Col>
                                                                        <Col
                                                                          md={9}
                                                                          sm={8}
                                                                          xs={8}
                                                                          className="mt-2 mt-sm-0"
                                                                        >
                                                                          <Input
                                                                            type="text"
                                                                            name="saas_core_member_description"
                                                                            value={
                                                                              cm?.description
                                                                            }
                                                                            // pattern={
                                                                            //   /^[a-z0-9-]{0,}$/g
                                                                            // }
                                                                            className="form-control"
                                                                            onChange={event => {
                                                                              let updatedCoreMembers =
                                                                                [
                                                                                  ...coreMembers,
                                                                                ];
                                                                              let valueAtIndex =
                                                                                updatedCoreMembers[
                                                                                  index
                                                                                ];
                                                                              valueAtIndex.description =
                                                                                event.target.value;
                                                                              updatedCoreMembers[
                                                                                index
                                                                              ] =
                                                                                valueAtIndex;
                                                                              setCoreMembers(
                                                                                updatedCoreMembers
                                                                              );
                                                                            }}
                                                                            // required={
                                                                            //   !!user?.is_saas_parent
                                                                            // }
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </div>
                                                                    {coreMembers.length >
                                                                      0 && (
                                                                      <button
                                                                        className="btn-del"
                                                                        onClick={e => {
                                                                          e.preventDefault();
                                                                          let updatedCoreMembers =
                                                                            [
                                                                              ...coreMembers,
                                                                            ];
                                                                          updatedCoreMembers.splice(
                                                                            index,
                                                                            1
                                                                          );
                                                                          setCoreMembers(
                                                                            updatedCoreMembers
                                                                          );
                                                                        }}
                                                                      >
                                                                        -
                                                                      </button>
                                                                    )}
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </Col>
                                                        {coreMembers.length <=
                                                          5 && (
                                                          <div
                                                            className="addbtnc"
                                                            style={{
                                                              right: " 220px",
                                                            }}
                                                          >
                                                            <button
                                                              className="btn-add"
                                                              onClick={e => {
                                                                e.preventDefault();
                                                                let updatedCoreMembers =
                                                                  [
                                                                    ...coreMembers,
                                                                  ];
                                                                updatedCoreMembers.push(
                                                                  {
                                                                    name: "",
                                                                    description:
                                                                      "",
                                                                    profile_picture:
                                                                      "",
                                                                  }
                                                                );
                                                                setCoreMembers(
                                                                  updatedCoreMembers
                                                                );
                                                                setDisplayCoreMembers(
                                                                  updatedCoreMembers
                                                                );
                                                              }}
                                                            >
                                                              {coreMembers.length
                                                                ? "Add More"
                                                                : "Add Member"}
                                                            </button>
                                                          </div>
                                                        )}
                                                        {coreMembers.length >
                                                          5 && (
                                                          <span className="text-danger">
                                                            you can only add
                                                            upto 6 core members
                                                            only
                                                          </span>
                                                        )}
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert
                                                            Portfolio{" "}
                                                            {/* <span className="text-danger">
                                                              *
                                                            </span> */}
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <ReactQuill
                                                            id="description"
                                                            className="minh"
                                                            placeholder=""
                                                            value={
                                                              user?.saas_portfolio ||
                                                              ""
                                                            }
                                                            onChange={data => {
                                                              let regex =
                                                                /<[^>]+>/g;
                                                              const matches =
                                                                data.match(
                                                                  regex
                                                                );
                                                              let totalLength = 0;
                                                              if (matches) {
                                                                for (let match of matches) {
                                                                  totalLength +=
                                                                    match.length;
                                                                }
                                                              }
                                                              let lengthOfCharacters =
                                                                data.length -
                                                                totalLength;
                                                              setUser({
                                                                ...user,
                                                                saas_portfolio:
                                                                  data,
                                                              });
                                                              setPortfolioLength(
                                                                lengthOfCharacters
                                                              );
                                                            }}
                                                            modules={
                                                              descriptionEditorModules
                                                            }
                                                            formats={
                                                              descriptionEditorFormats
                                                            }
                                                          />
                                                          <small>
                                                            Note: minimum 10
                                                            characters required.
                                                          </small>
                                                          <br />
                                                          <small
                                                            style={
                                                              1500 -
                                                                portfolioLength <=
                                                              10
                                                                ? {
                                                                    color:
                                                                      "red",
                                                                  }
                                                                : {}
                                                            }
                                                          >
                                                            {1500 -
                                                              portfolioLength <
                                                            0
                                                              ? `Please reduce the text.`
                                                              : `Remaining characters: ${
                                                                  1500 -
                                                                  portfolioLength
                                                                }`}
                                                          </small>
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Portfolio Image{" "}
                                                            {/* <span className="text-danger">
                                                              *
                                                            </span> */}
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          // md={9}
                                                          sm={4}
                                                          // xs={8}
                                                          className="mt-2 mt-sm-0 imgbor"
                                                        >
                                                          <DragDropRounded
                                                            file="portfolio_image"
                                                            titleText={
                                                              "Portfolio Image"
                                                            }
                                                            prevId={`portfolioImagePrev`}
                                                            src={
                                                              typeof displayPortfolioImage ===
                                                              "string"
                                                                ? displayPortfolioImage
                                                                : displayPortfolioImage
                                                                ? URL.createObjectURL(
                                                                    displayPortfolioImage
                                                                  )
                                                                : ""
                                                            }
                                                            setFile={({
                                                              file,
                                                            }) => {
                                                              setDisplayPortfolioImage(
                                                                file[0]
                                                              );
                                                            }}
                                                          />
                                                          <button
                                                            className="btn-del"
                                                            onClick={e => {
                                                              e.preventDefault();
                                                              setDisplayPortfolioImage(
                                                                ""
                                                              );
                                                            }}
                                                          >
                                                            -
                                                          </button>
                                                          <small>
                                                            Recommended size:
                                                            480 * 320
                                                          </small>
                                                          {/* <Input
                                                            type="file"
                                                            accept=".jpeg, .png, .jpg, .gif"
                                                            className="form-control"
                                                            placeholder="Select Logo"
                                                            onChange={event => {
                                                              setPortfolioImage(
                                                                {
                                                                  fileName:
                                                                    event.target
                                                                      .value,
                                                                  file: event
                                                                    .target
                                                                    .files[0],
                                                                }
                                                              );
                                                            }}
                                                            multiple={false}
                                                            required={
                                                              !!user?.is_saas_parent
                                                            }
                                                          />
                                                          {user?.portfolio_image && (
                                                            <>
                                                              <a
                                                                href={
                                                                  !user?.is_saas_parent
                                                                    ? null
                                                                    : user?.portfolio_image
                                                                }
                                                                target="_blank"
                                                              >
                                                                Saved Image
                                                              </a>
                                                            </>
                                                          )} */}
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Domain Expert Long
                                                            Description{" "}
                                                            {/* <span className="text-danger">
                                                              *
                                                            </span> */}
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <ReactQuill
                                                            id="description"
                                                            className="minh"
                                                            placeholder=""
                                                            value={
                                                              user?.saas_long_description ||
                                                              ""
                                                            }
                                                            onChange={data => {
                                                              let regex =
                                                                /<[^>]+>/g;
                                                              const matches =
                                                                data.match(
                                                                  regex
                                                                );
                                                              let totalLength = 0;
                                                              if (matches) {
                                                                for (let match of matches) {
                                                                  totalLength +=
                                                                    match.length;
                                                                }
                                                              }
                                                              let lengthOfCharacters =
                                                                data.length -
                                                                totalLength;
                                                              setUser({
                                                                ...user,
                                                                saas_long_description:
                                                                  data,
                                                              });
                                                              setLongDescriptionLength(
                                                                lengthOfCharacters
                                                              );
                                                            }}
                                                            modules={
                                                              descriptionEditorModules
                                                            }
                                                            formats={
                                                              descriptionEditorFormats
                                                            }
                                                          />
                                                          <small>
                                                            Note: minimum 10
                                                            characters required.
                                                          </small>
                                                          <br />
                                                          <small
                                                            style={
                                                              1500 -
                                                                longDescriptionLength <=
                                                              10
                                                                ? {
                                                                    color:
                                                                      "red",
                                                                  }
                                                                : {}
                                                            }
                                                          >
                                                            {1500 -
                                                              longDescriptionLength <
                                                            0
                                                              ? `Please reduce the text.`
                                                              : `Remaining characters: ${
                                                                  1500 -
                                                                  longDescriptionLength
                                                                }`}
                                                          </small>
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Show Deals To
                                                            Investors
                                                            {/* <span className="text-danger">
                                                              *
                                                            </span> */}
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Form1.Check
                                                            id={
                                                              "show_deal_data_yes"
                                                            }
                                                            name={
                                                              "show_deal_data"
                                                            }
                                                            type="radio"
                                                            inline
                                                            label={"Yes"}
                                                            checked={
                                                              user?.show_deal_data
                                                            }
                                                            onChange={event => {
                                                              if (
                                                                event.target
                                                                  .checked
                                                              ) {
                                                                setUser({
                                                                  ...user,
                                                                  show_deal_data: true,
                                                                });
                                                              }
                                                            }}
                                                          />
                                                          <Form1.Check
                                                            id={
                                                              "show_deal_data_no"
                                                            }
                                                            name={
                                                              "show_deal_data"
                                                            }
                                                            type="radio"
                                                            inline
                                                            label={"No"}
                                                            checked={
                                                              !user?.show_deal_data
                                                            }
                                                            onChange={event => {
                                                              if (
                                                                event.target
                                                                  .checked
                                                              ) {
                                                                setUser({
                                                                  ...user,
                                                                  show_deal_data: false,
                                                                });
                                                              }
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      <Row
                                                        className="mb-3 align-items-center"
                                                        style={{
                                                          marginTop: "3.75rem",
                                                        }}
                                                      >
                                                        <Col
                                                          md={3}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Form1.Check
                                                            type="switch"
                                                            label="Is Intent equals to Consent ?"
                                                            checked={
                                                              user
                                                                ?.intent_consent
                                                                ?.isEquals
                                                            }
                                                            onChange={event => {
                                                              let intent_consent =
                                                                user?.intent_consent ||
                                                                {};
                                                              intent_consent[
                                                                "isEquals"
                                                              ] =
                                                                event.target.checked;
                                                              setUser({
                                                                ...user,
                                                                intent_consent,
                                                              });
                                                            }}
                                                          />
                                                        </Col>
                                                        {user?.intent_consent
                                                          ?.isEquals && (
                                                          <Col
                                                            md={9}
                                                            sm={12}
                                                            xs={12}
                                                            className="mt-2 mt-sm-0"
                                                          >
                                                            <Row>
                                                              <Col
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className="mt-2 mt-sm-0"
                                                              >
                                                                <Label>
                                                                  Select consent
                                                                  % base on
                                                                  intent (Only
                                                                  for Poll)
                                                                </Label>
                                                              </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col>
                                                                <Select
                                                                  placeholder={
                                                                    "Select consent % base on intent"
                                                                  }
                                                                  options={
                                                                    consentToIntentList
                                                                  }
                                                                  onChange={value => {
                                                                    let intent_consent =
                                                                      user?.intent_consent ||
                                                                      {};
                                                                    intent_consent[
                                                                      "base_on"
                                                                    ] =
                                                                      value?.value;
                                                                    setUser({
                                                                      ...user,
                                                                      intent_consent,
                                                                    });
                                                                  }}
                                                                  value={
                                                                    user
                                                                      ?.intent_consent
                                                                      ?.base_on
                                                                      ? consentToIntentList.filter(
                                                                          value =>
                                                                            user
                                                                              ?.intent_consent
                                                                              ?.base_on ===
                                                                            value?.value
                                                                        )?.[0]
                                                                      : consentToIntentList[1]
                                                                  }
                                                                  // isClearable
                                                                />
                                                              </Col>
                                                              <Col>
                                                                <Input
                                                                  type="number"
                                                                  step="any"
                                                                  min="1"
                                                                  placeholder="intent equals to consent %"
                                                                  // required
                                                                  value={
                                                                    user
                                                                      ?.intent_consent
                                                                      ?.percent ??
                                                                    100
                                                                  }
                                                                  onChange={event => {
                                                                    let intent_consent =
                                                                      user?.intent_consent ||
                                                                      {};
                                                                    intent_consent[
                                                                      "percent"
                                                                    ] =
                                                                      event.target.value;
                                                                    setUser({
                                                                      ...user,
                                                                      intent_consent,
                                                                    });
                                                                  }}
                                                                />
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        )}
                                                      </Row>
                                                      {!!investmentVehicles?.length && (
                                                        <>
                                                          <Row
                                                            className="mb-3"
                                                            style={{
                                                              marginTop:
                                                                "3.75rem",
                                                            }}
                                                          >
                                                            <Col
                                                              md={3}
                                                              sm={4}
                                                              xs={4}
                                                            >
                                                              <Label>
                                                                {/* Syndicate Payout
                                                                (%) */}
                                                                Management fee
                                                                in excess of (%)
                                                              </Label>
                                                            </Col>

                                                            <Col
                                                              md={9}
                                                              sm={8}
                                                              xs={8}
                                                            >
                                                              <Row>
                                                                {investmentVehicles?.map(
                                                                  (
                                                                    vehicle,
                                                                    index
                                                                  ) => {
                                                                    let {
                                                                      value,
                                                                      label,
                                                                      currency,
                                                                      currencySymbol,
                                                                    } =
                                                                      vehicle ||
                                                                      {};

                                                                    if (
                                                                      !label
                                                                    ) {
                                                                      return null;
                                                                    }

                                                                    let syndicateFieldKey =
                                                                      getSyndicatePayoutSettingsKey(
                                                                        label
                                                                      );

                                                                    return (
                                                                      <Col
                                                                        key={
                                                                          syndicateFieldKey
                                                                        }
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={6}
                                                                        className="mb-2"
                                                                      >
                                                                        <Label>
                                                                          {
                                                                            label
                                                                          }{" "}
                                                                          (
                                                                          {
                                                                            currencySymbol
                                                                          }
                                                                          )
                                                                        </Label>
                                                                        <Input
                                                                          type="number"
                                                                          step="any"
                                                                          min="0"
                                                                          max="100"
                                                                          placeholder="Enter % of investor investment amount"
                                                                          name={
                                                                            syndicateFieldKey
                                                                          }
                                                                          value={
                                                                            user
                                                                              ?.payout_settings?.[
                                                                              syndicateFieldKey
                                                                            ]
                                                                          }
                                                                          onChange={event => {
                                                                            let value =
                                                                              event
                                                                                ?.target
                                                                                ?.value;

                                                                            setUser(
                                                                              {
                                                                                ...user,
                                                                                payout_settings:
                                                                                  {
                                                                                    ...(user?.payout_settings ||
                                                                                      {}),
                                                                                    [syndicateFieldKey]:
                                                                                      value
                                                                                        ? parseFloat(
                                                                                            value
                                                                                          )
                                                                                        : value,
                                                                                  },
                                                                              }
                                                                            );
                                                                          }}
                                                                        />
                                                                      </Col>
                                                                    );
                                                                  }
                                                                )}
                                                              </Row>

                                                              <Row className="mt-3 align-items-center">
                                                                <Col>
                                                                  <Form1.Switch
                                                                    id={`saas-${
                                                                      user?._id?.toString() ||
                                                                      ""
                                                                    }-vp-payout-enabled`}
                                                                    name={`saas-${
                                                                      user?._id?.toString() ||
                                                                      ""
                                                                    }-vp-payout-enabled`}
                                                                    type="switch"
                                                                    label="Enable VP Payout"
                                                                    checked={
                                                                      user?.vp_payout_enabled
                                                                    }
                                                                    onChange={e => {
                                                                      let checked =
                                                                        e.target
                                                                          .checked;
                                                                      setUser({
                                                                        ...user,
                                                                        vp_payout_enabled:
                                                                          checked,
                                                                      });
                                                                    }}
                                                                  />
                                                                </Col>
                                                              </Row>
                                                            </Col>
                                                          </Row>
                                                        </>
                                                      )}

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Registration Credits{" "}
                                                            {/* <span className="text-danger">
                                                              *
                                                            </span> */}
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Input
                                                            type="number"
                                                            step="any"
                                                            min="0"
                                                            placeholder="Registration Credits"
                                                            // required={
                                                            //   !!user?.is_saas_parent
                                                            // }
                                                            value={
                                                              user?.registration_credits
                                                            }
                                                            onChange={event => {
                                                              setUser({
                                                                ...user,
                                                                registration_credits:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Credits Per Lac Of
                                                            Investment
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Row>
                                                            <Col>
                                                              <Label>
                                                                Self Investments{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                            <Col>
                                                              <Label>
                                                                Referral
                                                                Investments{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="For Self Investments"
                                                                // required
                                                                value={
                                                                  user
                                                                    ?.credits_per_lac_of_investments
                                                                    ?.for_self
                                                                }
                                                                onChange={event => {
                                                                  let credits_per_lac_of_investments =
                                                                    user?.credits_per_lac_of_investments ||
                                                                    {};
                                                                  credits_per_lac_of_investments[
                                                                    "for_self"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    credits_per_lac_of_investments,
                                                                  });
                                                                }}
                                                                // disabled={
                                                                //   isPartnerEditDisabled
                                                                // }
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="For Referral Investments"
                                                                // required
                                                                value={
                                                                  user
                                                                    ?.credits_per_lac_of_investments
                                                                    ?.for_referral
                                                                }
                                                                onChange={event => {
                                                                  let credits_per_lac_of_investments =
                                                                    user?.credits_per_lac_of_investments ||
                                                                    {};
                                                                  credits_per_lac_of_investments[
                                                                    "for_referral"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    credits_per_lac_of_investments,
                                                                  });
                                                                }}
                                                                // disabled={
                                                                //   isPartnerEditDisabled
                                                                // }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      </Row>

                                                      <Row className="mt-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Subscription Fee
                                                            Credits
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Row>
                                                            <Col>
                                                              <Label>
                                                                Business Pack{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                            <Col>
                                                              <Label>
                                                                Sachet Pack{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                            <Col>
                                                              <Label>
                                                                Daily Credits{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>

                                                            <Col>
                                                              <Label>
                                                                Credit Limit{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="Business Pack"
                                                                // required={
                                                                //   !!user?.is_saas_parent
                                                                // }
                                                                value={
                                                                  user
                                                                    ?.subscription_fee
                                                                    ?.annually_amount
                                                                }
                                                                onChange={event => {
                                                                  let subscription_fee =
                                                                    user?.subscription_fee ||
                                                                    {};
                                                                  subscription_fee[
                                                                    "annually_amount"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    subscription_fee,
                                                                  });
                                                                }}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="Sachet Pack"
                                                                // required={
                                                                //   !!user?.is_saas_parent
                                                                // }
                                                                value={
                                                                  user
                                                                    ?.subscription_fee
                                                                    ?.quarterly_amount
                                                                }
                                                                onChange={event => {
                                                                  let subscription_fee =
                                                                    user?.subscription_fee ||
                                                                    {};
                                                                  subscription_fee[
                                                                    "quarterly_amount"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    subscription_fee,
                                                                  });
                                                                }}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="Daily Fee"
                                                                // required={
                                                                //   !!user?.is_saas_parent
                                                                // }
                                                                value={
                                                                  user
                                                                    ?.subscription_fee
                                                                    ?.per_day_deduction
                                                                }
                                                                onChange={event => {
                                                                  let subscription_fee =
                                                                    user?.subscription_fee ||
                                                                    {};
                                                                  subscription_fee[
                                                                    "per_day_deduction"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    subscription_fee,
                                                                  });
                                                                }}
                                                              />
                                                            </Col>

                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="Credit Limit"
                                                                // required={
                                                                //   !!user?.is_saas_parent
                                                                // }
                                                                value={
                                                                  user
                                                                    ?.subscription_fee
                                                                    ?.credit_limit
                                                                }
                                                                onChange={event => {
                                                                  let subscription_fee =
                                                                    user?.subscription_fee ||
                                                                    {};
                                                                  subscription_fee[
                                                                    "credit_limit"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    subscription_fee,
                                                                  });
                                                                }}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      </Row>

                                                      <Row className="mt-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Subscription Amounts
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={12}
                                                          xs={12}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Row>
                                                            <Col>
                                                              <Label>
                                                                Business Pack{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                            <Col>
                                                              <Label>
                                                                Sachet Pack{" "}
                                                                {/* <span className="text-danger">
                                                                  *
                                                                </span> */}
                                                              </Label>
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="Business Pack"
                                                                // required={
                                                                //   !!user?.is_saas_parent
                                                                // }
                                                                value={
                                                                  user?.amounts
                                                                    ?.annually_amount
                                                                }
                                                                onChange={event => {
                                                                  let amounts =
                                                                    user?.amounts ||
                                                                    {};
                                                                  amounts[
                                                                    "annually_amount"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    amounts,
                                                                  });
                                                                }}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Input
                                                                type="number"
                                                                step="any"
                                                                min="0"
                                                                placeholder="Sachet Pack"
                                                                // required={
                                                                //   !!user?.is_saas_parent
                                                                // }
                                                                value={
                                                                  user?.amounts
                                                                    ?.quarterly_amount
                                                                }
                                                                onChange={event => {
                                                                  let amounts =
                                                                    user?.amounts ||
                                                                    {};
                                                                  amounts[
                                                                    "quarterly_amount"
                                                                  ] =
                                                                    event.target.value;
                                                                  setUser({
                                                                    ...user,
                                                                    amounts,
                                                                  });
                                                                }}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      </Row>

                                                      <Row className="mt-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Include Other
                                                            Startup Domain
                                                            Expert
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Form1.Switch
                                                            id={`is-partner-${user._id}-select-all-startup-saas`}
                                                            name={`is-partner-${user._id}-select-all-startup-saas`}
                                                            type="switch"
                                                            label="Select All Startup Domain Expert"
                                                            checked={
                                                              user?.select_all_startup_saas
                                                            }
                                                            onChange={e => {
                                                              let { checked } =
                                                                e.target;
                                                              setUser({
                                                                ...user,
                                                                select_all_startup_saas:
                                                                  checked,
                                                              });
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      {!user?.select_all_startup_saas && (
                                                        <>
                                                          <Row className="mt-0 mb-0 align-items-center">
                                                            <Col
                                                              md={3}
                                                              sm={4}
                                                              xs={4}
                                                            ></Col>
                                                            <Col>
                                                              <Select
                                                                placeholder={
                                                                  "Select Domain Expert"
                                                                }
                                                                isMulti
                                                                options={saasPartnersList.sort(
                                                                  (a, b) => {
                                                                    if (
                                                                      a?.label?.toLowerCase() <
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return -1;
                                                                    }
                                                                    if (
                                                                      a?.label?.toLowerCase() >
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return 1;
                                                                    }
                                                                    return 0;
                                                                  }
                                                                )}
                                                                onChange={value => {
                                                                  let values =
                                                                    [];
                                                                  if (value) {
                                                                    for (let selected of value) {
                                                                      if (
                                                                        !selected?.value
                                                                      ) {
                                                                        continue;
                                                                      }
                                                                      values.push(
                                                                        selected.value
                                                                      );
                                                                    }
                                                                  }
                                                                  setUser({
                                                                    ...user,
                                                                    visible_startups:
                                                                      values,
                                                                  });
                                                                }}
                                                                value={saasPartnersList.filter(
                                                                  value =>
                                                                    value &&
                                                                    user?.visible_startups?.includes(
                                                                      value?.value
                                                                    )
                                                                )}
                                                                isClearable
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </>
                                                      )}

                                                      <Row className="mt-3 align-items-center">
                                                        <Col
                                                          md={3}
                                                          sm={4}
                                                          xs={4}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Label>
                                                            Include Other
                                                            Investor Domain
                                                            Expert
                                                          </Label>
                                                        </Col>
                                                        <Col
                                                          md={9}
                                                          sm={8}
                                                          xs={8}
                                                          className="mt-2 mt-sm-0"
                                                        >
                                                          <Form1.Switch
                                                            id={`is-partner-${user._id}-select-all-investor-saas`}
                                                            name={`is-partner-${user._id}-select-all-investor-saas`}
                                                            type="switch"
                                                            label="Select All Investor Domain Expert"
                                                            checked={
                                                              user?.select_all_investor_saas
                                                            }
                                                            onChange={e => {
                                                              let { checked } =
                                                                e.target;
                                                              setUser({
                                                                ...user,
                                                                select_all_investor_saas:
                                                                  checked,
                                                              });
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      {!user?.select_all_investor_saas && (
                                                        <>
                                                          <Row className="align-items-center">
                                                            <Col
                                                              md={3}
                                                              sm={4}
                                                              xs={4}
                                                            ></Col>
                                                            <Col>
                                                              <Select
                                                                placeholder={
                                                                  "Select Domain Expert"
                                                                }
                                                                isMulti
                                                                options={saasPartnersList.sort(
                                                                  (a, b) => {
                                                                    if (
                                                                      a?.label?.toLowerCase() <
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return -1;
                                                                    }
                                                                    if (
                                                                      a?.label?.toLowerCase() >
                                                                      b?.label?.toLowerCase()
                                                                    ) {
                                                                      return 1;
                                                                    }
                                                                    return 0;
                                                                  }
                                                                )}
                                                                onChange={value => {
                                                                  let values =
                                                                    [];
                                                                  if (value) {
                                                                    for (let selected of value) {
                                                                      if (
                                                                        !selected?.value
                                                                      ) {
                                                                        continue;
                                                                      }
                                                                      values.push(
                                                                        selected.value
                                                                      );
                                                                    }
                                                                  }
                                                                  setUser({
                                                                    ...user,
                                                                    visible_investors:
                                                                      values,
                                                                  });
                                                                }}
                                                                value={saasPartnersList.filter(
                                                                  value =>
                                                                    value &&
                                                                    user?.visible_investors?.includes(
                                                                      value.value
                                                                    )
                                                                )}
                                                                isClearable
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </>
                                                      )}

                                                      {user?.is_saas_parent ? (
                                                        <>
                                                          <Row className="mt-3 align-items-center">
                                                            <Col
                                                              md={3}
                                                              sm={4}
                                                              xs={4}
                                                            >
                                                              <label className="">
                                                                Domain Expert
                                                                Manager *:
                                                              </label>
                                                            </Col>
                                                            <Col>
                                                              <select
                                                                className="form-control"
                                                                required
                                                                onChange={e => {
                                                                  setInvestment_manager_id(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                              >
                                                                <option
                                                                  value={"null"}
                                                                >
                                                                  --Select
                                                                  Domain Expert
                                                                  Manager--
                                                                </option>
                                                                {investmentManagers &&
                                                                  investmentManagers.length >
                                                                    0 &&
                                                                  investmentManagers.map(
                                                                    (
                                                                      val,
                                                                      key
                                                                    ) => (
                                                                      <option
                                                                        key={
                                                                          key
                                                                        }
                                                                        value={
                                                                          val._id
                                                                        }
                                                                        selected={
                                                                          user
                                                                            .investment_manager_id
                                                                            ?._id ===
                                                                          val._id
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        {
                                                                          val.name
                                                                        }
                                                                      </option>
                                                                    )
                                                                  )}
                                                              </select>
                                                            </Col>
                                                          </Row>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  )}

                                                  {!user?.is_saas_parent && (
                                                    <>
                                                      <Row className="mb-3">
                                                        <Col>
                                                          <label className="">
                                                            Parent Domain Expert{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                          <Select
                                                            placeholder={
                                                              "Select Domain Expert Parent"
                                                            }
                                                            options={saasPartnersList.sort(
                                                              (a, b) => {
                                                                if (
                                                                  a?.label?.toLowerCase() <
                                                                  b?.label?.toLowerCase()
                                                                ) {
                                                                  return -1;
                                                                }
                                                                if (
                                                                  a?.label?.toLowerCase() >
                                                                  b?.label?.toLowerCase()
                                                                ) {
                                                                  return 1;
                                                                }
                                                                return 0;
                                                              }
                                                            )}
                                                            onChange={
                                                              setSelectedSaasPartner
                                                            }
                                                            value={
                                                              selectedSaasPartner
                                                            }
                                                            required={
                                                              !user?.is_saas_parent
                                                            }
                                                          />
                                                        </Col>
                                                      </Row>

                                                      <Row
                                                        className="mb-3"
                                                        hidden
                                                      >
                                                        <Col>
                                                          <label className="">
                                                            Secondary Syndicate
                                                          </label>
                                                          <Select
                                                            placeholder={
                                                              "Select Secondary Syndicate"
                                                            }
                                                            options={saasPartnersList
                                                              .filter(
                                                                saas =>
                                                                  saas?.value !==
                                                                  user
                                                                    ?.saas_parent_id
                                                                    ?._id
                                                              )
                                                              .sort((a, b) => {
                                                                if (
                                                                  a?.label?.toLowerCase() <
                                                                  b?.label?.toLowerCase()
                                                                ) {
                                                                  return -1;
                                                                }
                                                                if (
                                                                  a?.label?.toLowerCase() >
                                                                  b?.label?.toLowerCase()
                                                                ) {
                                                                  return 1;
                                                                }
                                                                return 0;
                                                              })}
                                                            isMulti
                                                            isClearable
                                                            onChange={value => {
                                                              let selected = [];
                                                              if (
                                                                value?.length
                                                              ) {
                                                                for (let sel of value) {
                                                                  if (
                                                                    sel.value
                                                                  ) {
                                                                    selected.push(
                                                                      sel.value
                                                                    );
                                                                  }
                                                                }
                                                              }

                                                              setUser({
                                                                ...user,
                                                                secondary_saas_ids:
                                                                  selected,
                                                              });
                                                            }}
                                                            value={saasPartnersList.filter(
                                                              value =>
                                                                value &&
                                                                user?.secondary_saas_ids?.includes(
                                                                  value.value
                                                                )
                                                            )}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      {/* <Row className="mb-3">
                                                        <Col>
                                                          <SyndicateOptions
                                                            user_id={user?._id}
                                                          />
                                                        </Col>
                                                      </Row> */}
                                                    </>
                                                  )}
                                                </Col>
                                              </Row>

                                              <Row className="mt-3">
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </AvForm>
                                          </TabPane>

                                          <TabPane tabId="5">
                                            <Row form className="mt-3">
                                              <Col className="col-12">
                                                <Row className="">
                                                  <Col>
                                                    <SyndicateOptions
                                                      user_id={user?._id}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </TabPane>

                                          <TabPane tabId="3">
                                            <Row className="mt-3">
                                              <Col xs={12}>
                                                <Form1.Check
                                                  id={user._id}
                                                  name={`is-partner-${user._id}`}
                                                  type="switch"
                                                  className="h5"
                                                  label={`${
                                                    !user.isPartner
                                                      ? "Make"
                                                      : "Remove"
                                                  } Partner`}
                                                  checked={user.isPartner}
                                                  onChange={e => {
                                                    setUser({
                                                      ...user,
                                                      isPartner:
                                                        e.target.checked,
                                                    });
                                                  }}
                                                />
                                              </Col>
                                            </Row>

                                            {!partnerUserInfo?.is_saas_parent && (
                                              <>
                                                <Row className="mt-3 align-items-center">
                                                  <Col
                                                    md={3}
                                                    sm={4}
                                                    xs={4}
                                                    className="mt-2 mt-sm-0"
                                                  >
                                                    <Label>
                                                      Reference Partner{" "}
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </Label>
                                                  </Col>
                                                  <Col
                                                    md={9}
                                                    sm={8}
                                                    xs={8}
                                                    className="mt-2 mt-sm-0"
                                                  >
                                                    <Select
                                                      className="mt-1"
                                                      placeholder={
                                                        "Select Partner"
                                                      }
                                                      options={allPartners
                                                        .filter(
                                                          partner =>
                                                            partner.value !==
                                                            user._id
                                                        )
                                                        .sort((a, b) => {
                                                          if (
                                                            a?.label?.toLowerCase() <
                                                            b?.label?.toLowerCase()
                                                          ) {
                                                            return -1;
                                                          }
                                                          if (
                                                            a?.label?.toLowerCase() >
                                                            b?.label?.toLowerCase()
                                                          ) {
                                                            return 1;
                                                          }
                                                          return 0;
                                                        })}
                                                      onChange={value => {
                                                        setSelectedRefPartner(
                                                          value
                                                        );
                                                      }}
                                                      value={selectedRefPartner}
                                                      isDisabled={
                                                        isPartnerEditDisabled
                                                      }
                                                      // isClearable
                                                    />
                                                  </Col>
                                                </Row>
                                              </>
                                            )}

                                            <Row
                                              className="mt-3 align-items-middle"
                                              hidden
                                            >
                                              <Col sm={6} xs={6}>
                                                <Row>
                                                  <Col
                                                    md={6}
                                                    sm={8}
                                                    xs={9}
                                                    className="mt-2 mt-sm-0"
                                                  >
                                                    <Label>
                                                      All Startups Visible
                                                    </Label>
                                                  </Col>
                                                  <Col
                                                    md={6}
                                                    sm={4}
                                                    xs={3}
                                                    className="mt-2 mt-sm-0"
                                                  >
                                                    <Form1.Check
                                                      type="switch"
                                                      checked={
                                                        partnerUserInfo?.see_all_startups
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          see_all_startups:
                                                            event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col sm={6} xs={6}>
                                                <Row>
                                                  <Col
                                                    md={6}
                                                    sm={8}
                                                    xs={9}
                                                    className="mt-2 mt-sm-0"
                                                  >
                                                    <Label>
                                                      All Investors Visible
                                                    </Label>
                                                  </Col>
                                                  <Col
                                                    md={6}
                                                    sm={4}
                                                    xs={3}
                                                    className="mt-2 mt-sm-0"
                                                  >
                                                    <Form1.Check
                                                      type="switch"
                                                      checked={
                                                        partnerUserInfo?.see_all_investors
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          see_all_investors:
                                                            event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>

                                            <Row className="mt-3">
                                              <Col
                                                md={3}
                                                sm={4}
                                                xs={4}
                                                className="mt-2 mt-sm-0"
                                              >
                                                <Label>VP Type</Label>
                                              </Col>
                                              <Col
                                                md={9}
                                                sm={8}
                                                xs={8}
                                                className="mt-2 mt-sm-0"
                                              >
                                                <Row>
                                                  <Col md={6} sm={12}>
                                                    <Form1.Check
                                                      id="vp-individual"
                                                      name="vp-individual"
                                                      type="radio"
                                                      inline
                                                      label="VP - Individual"
                                                      checked={
                                                        partnerUserInfo?.vp_individual
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          vp_individual:
                                                            event.target
                                                              .checked,
                                                          vp_company:
                                                            !event.target
                                                              .checked,
                                                          vp_syndicate:
                                                            !event.target
                                                              .checked,
                                                          vp_syndicate_subuser:
                                                            !event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>

                                                  <Col md={6} sm={12}>
                                                    <Form1.Check
                                                      id="vp-company"
                                                      name="vp-company"
                                                      type="radio"
                                                      inline
                                                      label="VP - Company"
                                                      checked={
                                                        partnerUserInfo?.vp_company
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          vp_individual:
                                                            !event.target
                                                              .checked,
                                                          vp_company:
                                                            event.target
                                                              .checked,
                                                          vp_syndicate:
                                                            !event.target
                                                              .checked,
                                                          vp_syndicate_subuser:
                                                            !event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>

                                                  <Col md={6} sm={12}>
                                                    <Form1.Check
                                                      id="vp-syndicate"
                                                      name="vp-syndicate"
                                                      type="radio"
                                                      inline
                                                      label="VP - Syndicate"
                                                      checked={
                                                        partnerUserInfo?.vp_syndicate
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          vp_individual:
                                                            !event.target
                                                              .checked,
                                                          vp_company:
                                                            !event.target
                                                              .checked,
                                                          vp_syndicate:
                                                            event.target
                                                              .checked,
                                                          vp_syndicate_subuser:
                                                            !event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>

                                                  <Col md={6} sm={12}>
                                                    <Form1.Check
                                                      id="vp-syndicate-subuser"
                                                      name="vp-syndicate-subuser"
                                                      type="radio"
                                                      inline
                                                      label="VP - Syndicate SubUser"
                                                      checked={
                                                        partnerUserInfo?.vp_syndicate_subuser
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          vp_individual:
                                                            !event.target
                                                              .checked,
                                                          vp_company:
                                                            !event.target
                                                              .checked,
                                                          vp_syndicate:
                                                            !event.target
                                                              .checked,
                                                          vp_syndicate_subuser:
                                                            event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>

                                            <Row className="mt-3 pt-2 pb-2 border border-secondary rounded">
                                              <Col md={12}>
                                                <Row className="text-center">
                                                  <Col md={12}>
                                                    <Label>
                                                      Account Details
                                                    </Label>
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col md={12}>
                                                <Row className="align-items-center">
                                                  <Col
                                                    md={3}
                                                    sm={4}
                                                    xs={4}
                                                    className="mt-2"
                                                  >
                                                    <Label>Bank Name</Label>
                                                  </Col>
                                                  <Col
                                                    md={9}
                                                    sm={8}
                                                    xs={8}
                                                    className="mt-2"
                                                  >
                                                    <Input
                                                      type="text"
                                                      placeholder="Bank Name"
                                                      value={
                                                        partnerUserInfo
                                                          ?.account_details
                                                          ?.bank_name
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          account_details: {
                                                            ...(partnerUserInfo?.account_details ||
                                                              {}),
                                                            bank_name:
                                                              event.target
                                                                .value,
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col md={12}>
                                                <Row className="align-items-center">
                                                  <Col
                                                    md={3}
                                                    sm={4}
                                                    xs={4}
                                                    className="mt-2"
                                                  >
                                                    <Label>
                                                      Account Number
                                                    </Label>
                                                  </Col>
                                                  <Col
                                                    md={9}
                                                    sm={8}
                                                    xs={8}
                                                    className="mt-2"
                                                  >
                                                    <Input
                                                      type="text"
                                                      placeholder="Account Number"
                                                      value={
                                                        partnerUserInfo
                                                          ?.account_details
                                                          ?.account_number
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          account_details: {
                                                            ...(partnerUserInfo?.account_details ||
                                                              {}),
                                                            account_number:
                                                              event.target
                                                                .value,
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col md={12}>
                                                <Row className="align-items-center">
                                                  <Col
                                                    md={3}
                                                    sm={4}
                                                    xs={4}
                                                    className="mt-2"
                                                  >
                                                    <Label>Bank Address</Label>
                                                  </Col>
                                                  <Col
                                                    md={9}
                                                    sm={8}
                                                    xs={8}
                                                    className="mt-2"
                                                  >
                                                    <Input
                                                      type="text"
                                                      placeholder="Bank Address"
                                                      value={
                                                        partnerUserInfo
                                                          ?.account_details
                                                          ?.bank_address
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          account_details: {
                                                            ...(partnerUserInfo?.account_details ||
                                                              {}),
                                                            bank_address:
                                                              event.target
                                                                .value,
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col md={12}>
                                                <Row className="align-items-center">
                                                  <Col
                                                    md={3}
                                                    sm={4}
                                                    xs={4}
                                                    className="mt-2"
                                                  >
                                                    <Label>IFSC Code</Label>
                                                  </Col>
                                                  <Col
                                                    md={9}
                                                    sm={8}
                                                    xs={8}
                                                    className="mt-2"
                                                  >
                                                    <Input
                                                      type="text"
                                                      placeholder="IFSC Code"
                                                      value={
                                                        partnerUserInfo
                                                          ?.account_details
                                                          ?.ifsc_code
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          account_details: {
                                                            ...(partnerUserInfo?.account_details ||
                                                              {}),
                                                            ifsc_code:
                                                              event.target
                                                                .value,
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col md={12}>
                                                <Row className="align-items-center">
                                                  <Col
                                                    md={3}
                                                    sm={4}
                                                    xs={4}
                                                    className="mt-2"
                                                  >
                                                    <Label>SWIFT Code</Label>
                                                  </Col>
                                                  <Col
                                                    md={9}
                                                    sm={8}
                                                    xs={8}
                                                    className="mt-2"
                                                  >
                                                    <Input
                                                      type="text"
                                                      placeholder="SWIFT Code"
                                                      value={
                                                        partnerUserInfo
                                                          ?.account_details
                                                          ?.swift_code
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          account_details: {
                                                            ...(partnerUserInfo?.account_details ||
                                                              {}),
                                                            swift_code:
                                                              event.target
                                                                .value,
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>

                                            {selectedRefPartner?.value && (
                                              <>
                                                <Row
                                                  className="mt-3 align-items-middle"
                                                  hidden
                                                >
                                                  <Col>
                                                    <Form1.Check
                                                      type="switch"
                                                      label="Display Collection"
                                                      checked={
                                                        partnerUserInfo?.display_collection
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          display_collection:
                                                            event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Form1.Check
                                                      type="switch"
                                                      label="Display Commision"
                                                      checked={
                                                        partnerUserInfo?.display_commision
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          display_commision:
                                                            event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="mt-3 align-items-middle">
                                                  <Col>
                                                    <Form1.Check
                                                      type="switch"
                                                      label="Get Payout"
                                                      checked={
                                                        partnerUserInfo?.can_get_payout
                                                      }
                                                      onChange={event => {
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          can_get_payout:
                                                            event.target
                                                              .checked,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                  {!!partnerUserInfo?.is_saas_parent ||
                                                  !!partnerUserInfo?.can_get_payout ? (
                                                    <>
                                                      <Col>
                                                        <Form1.Check
                                                          type="switch"
                                                          label="Apply Parent Settings"
                                                          checked={
                                                            partnerUserInfo?.apply_parent_settings
                                                          }
                                                          onChange={event => {
                                                            setPartnerUserInfo({
                                                              ...partnerUserInfo,
                                                              apply_parent_settings:
                                                                event.target
                                                                  .checked,
                                                            });
                                                          }}
                                                          disabled={
                                                            isPartnerEditDisabled
                                                          }
                                                        />
                                                      </Col>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </Row>
                                              </>
                                            )}

                                            {/* Level 1 settings */}
                                            {!!partnerUserInfo?.is_saas_parent ||
                                            (!!partnerUserInfo?.can_get_payout &&
                                              !partnerUserInfo?.apply_parent_settings) ? (
                                              <>
                                                <Row className="mt-3">
                                                  {!!vpPayoutEnabledSyndicates?.length &&
                                                    !!investmentVehicles?.length && (
                                                      <>
                                                        <Accordion flush>
                                                          {vpPayoutEnabledSyndicates?.map(
                                                            (
                                                              syndicate,
                                                              idx
                                                            ) => {
                                                              let {
                                                                label:
                                                                  syndicateLabel,
                                                                value:
                                                                  syndicateValue,
                                                              } = syndicate;

                                                              return (
                                                                <Accordion.Item
                                                                  eventKey={
                                                                    syndicateValue
                                                                  }
                                                                >
                                                                  <Accordion.Header>
                                                                    <span
                                                                      className={`${
                                                                        !!partnerSettingsError?.find(
                                                                          settingsError =>
                                                                            settingsError?.saas_parent_id ===
                                                                              syndicateValue &&
                                                                            Object.keys(
                                                                              settingsError?.settings ||
                                                                                {}
                                                                            ).some(
                                                                              key =>
                                                                                !!settingsError
                                                                                  ?.settings?.[
                                                                                  key
                                                                                ]
                                                                            )
                                                                        )
                                                                          ? "text-danger"
                                                                          : ""
                                                                      }`}
                                                                    >
                                                                      {
                                                                        syndicateLabel
                                                                      }
                                                                    </span>
                                                                  </Accordion.Header>
                                                                  <Accordion.Body>
                                                                    <Row>
                                                                      <Accordion
                                                                        flush
                                                                        alwaysOpen
                                                                      >
                                                                        {investmentVehicles?.map(
                                                                          (
                                                                            vehicle,
                                                                            index
                                                                          ) => {
                                                                            let {
                                                                              value,
                                                                              label,
                                                                              currency,
                                                                              currencySymbol,
                                                                            } =
                                                                              vehicle ||
                                                                              {};

                                                                            if (
                                                                              !label
                                                                            ) {
                                                                              return null;
                                                                            }

                                                                            let referralInvestmentFieldKey =
                                                                              getReferralInvestmentSettingsKey(
                                                                                label
                                                                              );
                                                                            let selfInvestmentFieldKey =
                                                                              getSelfInvestmentSettingsKey(
                                                                                label
                                                                              );

                                                                            return (
                                                                              <Accordion.Item
                                                                                eventKey={
                                                                                  syndicateValue +
                                                                                  index.toString()
                                                                                }
                                                                              >
                                                                                <Accordion.Header>
                                                                                  <span
                                                                                    className={`${
                                                                                      !!partnerSettingsError?.find(
                                                                                        settingsError =>
                                                                                          settingsError?.saas_parent_id ===
                                                                                          syndicateValue
                                                                                      )
                                                                                        ?.settings?.[
                                                                                        referralInvestmentFieldKey
                                                                                      ] ||
                                                                                      !!partnerSettingsError?.find(
                                                                                        settingsError =>
                                                                                          settingsError?.saas_parent_id ===
                                                                                          syndicateValue
                                                                                      )
                                                                                        ?.settings?.[
                                                                                        selfInvestmentFieldKey
                                                                                      ]
                                                                                        ? "text-danger"
                                                                                        : ""
                                                                                    }`}
                                                                                  >{`${label} (${currencySymbol})`}</span>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                  <Row className="mt-3">
                                                                                    <Col
                                                                                      sm={
                                                                                        6
                                                                                      }
                                                                                      xs={
                                                                                        12
                                                                                      }
                                                                                      className="mt-2 mt-sm-0 d-flex flex-column"
                                                                                    >
                                                                                      <Label>
                                                                                        Investor/Referral
                                                                                        Investment
                                                                                        Amount
                                                                                        (%)
                                                                                        {!!partnerSettingsError?.find(
                                                                                          settingsError =>
                                                                                            settingsError?.saas_parent_id ===
                                                                                            syndicateValue
                                                                                        )
                                                                                          ?.settings?.[
                                                                                          referralInvestmentFieldKey
                                                                                        ] && (
                                                                                          <>
                                                                                            <br />
                                                                                            <span className="text-danger">
                                                                                              {
                                                                                                partnerSettingsError?.find(
                                                                                                  settingsError =>
                                                                                                    settingsError?.saas_parent_id ===
                                                                                                    syndicateValue
                                                                                                )
                                                                                                  ?.settings?.[
                                                                                                  referralInvestmentFieldKey
                                                                                                ]
                                                                                              }
                                                                                            </span>
                                                                                          </>
                                                                                        )}
                                                                                      </Label>
                                                                                      <Input
                                                                                        type="number"
                                                                                        step="any"
                                                                                        min="0"
                                                                                        max="100"
                                                                                        placeholder="Enter % Of Investor/Referral Investment Amount"
                                                                                        name={
                                                                                          syndicateValue +
                                                                                          referralInvestmentFieldKey
                                                                                        }
                                                                                        value={
                                                                                          partnerUserInfo?.vp_settings?.find(
                                                                                            syndicate =>
                                                                                              syndicate?.saas_parent_id ===
                                                                                              syndicateValue
                                                                                          )
                                                                                            ?.settings?.[
                                                                                            referralInvestmentFieldKey
                                                                                          ]
                                                                                        }
                                                                                        onChange={event => {
                                                                                          let value =
                                                                                            event
                                                                                              .target
                                                                                              .value;

                                                                                          let error =
                                                                                            !!value &&
                                                                                            (parseFloat(
                                                                                              value
                                                                                            ) >
                                                                                              100 ||
                                                                                              parseFloat(
                                                                                                value
                                                                                              ) <
                                                                                                0)
                                                                                              ? "Please enter value between 0-100"
                                                                                              : "";

                                                                                          let existingErrors =
                                                                                            partnerSettingsError?.find(
                                                                                              settingsError =>
                                                                                                settingsError?.saas_parent_id ===
                                                                                                syndicateValue
                                                                                            ) ||
                                                                                            {};
                                                                                          existingErrors[
                                                                                            "saas_parent_id"
                                                                                          ] =
                                                                                            syndicateValue;
                                                                                          existingErrors[
                                                                                            "settings"
                                                                                          ] =
                                                                                            {
                                                                                              ...(existingErrors?.settings ||
                                                                                                {}),
                                                                                              [referralInvestmentFieldKey]:
                                                                                                error,
                                                                                            };

                                                                                          setPartnerSettingsError(
                                                                                            [
                                                                                              ...partnerSettingsError?.filter(
                                                                                                settingsError =>
                                                                                                  settingsError?.saas_parent_id !==
                                                                                                  syndicateValue
                                                                                              ),
                                                                                              existingErrors,
                                                                                            ]
                                                                                          );

                                                                                          let existingSettings =
                                                                                            partnerUserInfo?.vp_settings?.find(
                                                                                              syndicate =>
                                                                                                syndicate?.saas_parent_id ===
                                                                                                syndicateValue
                                                                                            ) ||
                                                                                            {};

                                                                                          existingSettings[
                                                                                            "saas_parent_id"
                                                                                          ] =
                                                                                            syndicateValue;
                                                                                          existingSettings[
                                                                                            "settings"
                                                                                          ] =
                                                                                            {
                                                                                              ...(existingSettings?.settings ||
                                                                                                {}),
                                                                                              [referralInvestmentFieldKey]:
                                                                                                value
                                                                                                  ? parseFloat(
                                                                                                      value
                                                                                                    )
                                                                                                  : value,
                                                                                            };

                                                                                          setPartnerUserInfo(
                                                                                            {
                                                                                              ...partnerUserInfo,
                                                                                              vp_settings:
                                                                                                [
                                                                                                  ...(partnerUserInfo?.vp_settings?.filter(
                                                                                                    syndicate =>
                                                                                                      syndicate?.saas_parent_id !==
                                                                                                      syndicateValue
                                                                                                  ) ||
                                                                                                    []),
                                                                                                  existingSettings,
                                                                                                ],
                                                                                            }
                                                                                          );
                                                                                        }}
                                                                                        disabled={
                                                                                          isPartnerEditDisabled
                                                                                        }
                                                                                      />
                                                                                    </Col>

                                                                                    {!isPartnerEditDisabled &&
                                                                                      partnerUserInfo?.vp_individual && (
                                                                                        <>
                                                                                          <Col
                                                                                            sm={
                                                                                              6
                                                                                            }
                                                                                            xs={
                                                                                              12
                                                                                            }
                                                                                            className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                                                                                          >
                                                                                            <Label>
                                                                                              Refund
                                                                                              For
                                                                                              Self-Investment
                                                                                              Amount
                                                                                              (%)
                                                                                              {!!partnerSettingsError?.find(
                                                                                                settingsError =>
                                                                                                  settingsError?.saas_parent_id ===
                                                                                                  syndicateValue
                                                                                              )
                                                                                                ?.settings?.[
                                                                                                selfInvestmentFieldKey
                                                                                              ] && (
                                                                                                <>
                                                                                                  <br />
                                                                                                  <span className="text-danger">
                                                                                                    {
                                                                                                      partnerSettingsError?.find(
                                                                                                        settingsError =>
                                                                                                          settingsError?.saas_parent_id ===
                                                                                                          syndicateValue
                                                                                                      )
                                                                                                        ?.settings?.[
                                                                                                        selfInvestmentFieldKey
                                                                                                      ]
                                                                                                    }
                                                                                                  </span>
                                                                                                </>
                                                                                              )}
                                                                                            </Label>
                                                                                            <Input
                                                                                              type="number"
                                                                                              step="any"
                                                                                              min="0"
                                                                                              max="100"
                                                                                              placeholder="Enter Transaction Fee Refund For Self-Investments (%)"
                                                                                              name={
                                                                                                syndicateValue +
                                                                                                selfInvestmentFieldKey
                                                                                              }
                                                                                              value={
                                                                                                partnerUserInfo?.vp_settings?.find(
                                                                                                  syndicate =>
                                                                                                    syndicate?.saas_parent_id ===
                                                                                                    syndicateValue
                                                                                                )
                                                                                                  ?.settings?.[
                                                                                                  selfInvestmentFieldKey
                                                                                                ]
                                                                                              }
                                                                                              onChange={event => {
                                                                                                let value =
                                                                                                  event
                                                                                                    .target
                                                                                                    .value;

                                                                                                let error =
                                                                                                  !!value &&
                                                                                                  (parseFloat(
                                                                                                    value
                                                                                                  ) >
                                                                                                    100 ||
                                                                                                    parseFloat(
                                                                                                      value
                                                                                                    ) <
                                                                                                      0)
                                                                                                    ? "Please enter value between 0-100"
                                                                                                    : "";

                                                                                                let existingErrors =
                                                                                                  partnerSettingsError?.find(
                                                                                                    settingsError =>
                                                                                                      settingsError?.saas_parent_id ===
                                                                                                      syndicateValue
                                                                                                  ) ||
                                                                                                  {};
                                                                                                existingErrors[
                                                                                                  "saas_parent_id"
                                                                                                ] =
                                                                                                  syndicateValue;
                                                                                                existingErrors[
                                                                                                  "settings"
                                                                                                ] =
                                                                                                  {
                                                                                                    ...(existingErrors?.settings ||
                                                                                                      {}),
                                                                                                    [selfInvestmentFieldKey]:
                                                                                                      error,
                                                                                                  };

                                                                                                setPartnerSettingsError(
                                                                                                  [
                                                                                                    ...partnerSettingsError?.filter(
                                                                                                      settingsError =>
                                                                                                        settingsError?.saas_parent_id !==
                                                                                                        syndicateValue
                                                                                                    ),
                                                                                                    existingErrors,
                                                                                                  ]
                                                                                                );

                                                                                                let existingSettings =
                                                                                                  partnerUserInfo?.vp_settings?.find(
                                                                                                    syndicate =>
                                                                                                      syndicate?.saas_parent_id ===
                                                                                                      syndicateValue
                                                                                                  );

                                                                                                existingSettings[
                                                                                                  "saas_parent_id"
                                                                                                ] =
                                                                                                  syndicateValue;
                                                                                                existingSettings[
                                                                                                  "settings"
                                                                                                ] =
                                                                                                  {
                                                                                                    ...(existingSettings?.settings ||
                                                                                                      {}),
                                                                                                    [selfInvestmentFieldKey]:
                                                                                                      value
                                                                                                        ? parseFloat(
                                                                                                            value
                                                                                                          )
                                                                                                        : value,
                                                                                                  };

                                                                                                setPartnerUserInfo(
                                                                                                  {
                                                                                                    ...partnerUserInfo,
                                                                                                    vp_settings:
                                                                                                      [
                                                                                                        ...(partnerUserInfo?.vp_settings?.filter(
                                                                                                          syndicate =>
                                                                                                            syndicate?.saas_parent_id !==
                                                                                                            syndicateValue
                                                                                                        ) ||
                                                                                                          []),
                                                                                                        existingSettings,
                                                                                                      ],
                                                                                                  }
                                                                                                );
                                                                                              }}
                                                                                              disabled={
                                                                                                isPartnerEditDisabled &&
                                                                                                partnerUserInfo?.vp_individual
                                                                                              }
                                                                                            />
                                                                                            <span className="text-secondary">
                                                                                              <i className="fa fa-asterisk text-secondary" />{" "}
                                                                                              Only
                                                                                              for
                                                                                              VP
                                                                                              -
                                                                                              Individual
                                                                                            </span>
                                                                                          </Col>
                                                                                        </>
                                                                                      )}
                                                                                  </Row>
                                                                                </Accordion.Body>
                                                                              </Accordion.Item>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </Accordion>
                                                                    </Row>
                                                                  </Accordion.Body>
                                                                </Accordion.Item>
                                                              );
                                                            }
                                                          )}
                                                        </Accordion>
                                                      </>
                                                    )}
                                                </Row>

                                                <Row className="mt-3">
                                                  <Col
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                    className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                                                  >
                                                    <Label>
                                                      Subscription Fee (%)
                                                    </Label>
                                                    <Input
                                                      type="number"
                                                      step="any"
                                                      min="0"
                                                      max="100"
                                                      placeholder="Enter Subscription Fee (%)"
                                                      value={
                                                        partnerUserInfo
                                                          ?.level_1_settings
                                                          ?.subscription_fee
                                                          ?.amount
                                                      }
                                                      onChange={event => {
                                                        let level_1_settings =
                                                          partnerUserInfo?.level_1_settings ||
                                                          {};
                                                        let subscription_fee =
                                                          level_1_settings?.subscription_fee ||
                                                          {};
                                                        subscription_fee[
                                                          "amount"
                                                        ] = event.target.value;
                                                        level_1_settings[
                                                          "subscription_fee"
                                                        ] = subscription_fee;
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          level_1_settings,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                  <Col
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                    className="mt-2 mt-md-0 d-flex flex-column justify-content-between"
                                                  >
                                                    <Label>Carry Fee (%)</Label>
                                                    <Input
                                                      type="number"
                                                      step="any"
                                                      min="0"
                                                      max="100"
                                                      placeholder="Enter Carry Fee (%)"
                                                      value={
                                                        partnerUserInfo
                                                          ?.level_1_settings
                                                          ?.carry_fee?.amount
                                                      }
                                                      onChange={event => {
                                                        let level_1_settings =
                                                          partnerUserInfo?.level_1_settings ||
                                                          {};
                                                        let carry_fee =
                                                          level_1_settings?.carry_fee ||
                                                          {};
                                                        carry_fee["amount"] =
                                                          event.target.value;
                                                        level_1_settings[
                                                          "carry_fee"
                                                        ] = carry_fee;
                                                        setPartnerUserInfo({
                                                          ...partnerUserInfo,
                                                          level_1_settings,
                                                        });
                                                      }}
                                                      disabled={
                                                        isPartnerEditDisabled
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </>
                                            ) : (
                                              <></>
                                            )}

                                            <Row className="mt-3 text-end">
                                              <Col>
                                                <Button
                                                  onClick={
                                                    handleSubmitPartnerUserInfo
                                                  }
                                                >
                                                  Update Details
                                                </Button>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UsersList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
  allAccessSettings: PropTypes.array,
};

const mapStateToProps = state => ({
  allAccessSettings: state?.frontendAccessSetting?.allAccessSettings,
});

const mapDispatchToProps = dispatch => ({
  getAllAccessSettingsFunction: payload =>
    dispatch(getAllAccessSettings(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
